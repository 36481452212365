import React, { createRef } from "react";
import HttpService from "../Common/HttpService";
import { connect } from "react-redux";
import { Button, Input, message, Row, Space, Tooltip, Typography, Form, Select, InputNumber  } from "antd";
import { login, setBundleId } from "../Actions/Login";
import { bindActionCreators } from "redux";
import { initializeApp } from "../Actions/InitAction";
import firebaseClient from "../config/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import axios from "axios";
import { Store } from "../Store/store";
import OtpInput from "react-otp-input";

const buttonLoader = <LoadingOutlined style={{ fontSize: 28, color: "white", margin: "-8px 5px 0px 8px" }} spin />;
const buttonLoaderDark = <LoadingOutlined style={{ fontSize: 28, color: "#478aee", margin: "-8px 5px 0px 8px" }} spin />;
class Login extends React.Component
{
	state =
	{
		name: "",
		content: [],
		showLoginOptions: false,
		showEmailBox: true,
		showOtpBox: true,
		phoneNumber: "",
		countryCode: "91",
		otp: "",
		previousOtp: "",
		focusOtpBox: {focus: false, index: 0},
		phoneNumberInfo: "none",
		otpInfo: "none",
		showBackButton: false,
		isResendEnabled: false,
		otpTimer: 0,
		otpSent: false,
		otpSending: false
	}

	constructor(props)
	{
		super(props);

		document.title = "StyleSend - Login";

		this.phoneRef = React.createRef();
		this.otpRef = React.createRef();

		// this.init = this.init.bind(this);
		this.offlineInit = this.offlineInit.bind(this);
		this.onPhoneLogin = this.onPhoneLogin.bind(this);
		this.onFetchOtp = this.onFetchOtp.bind(this);
		// this.onPhoneChange = this.onPhoneChange.bind(this);
		this.onOTPChange = this.onOTPChange.bind(this);
		this.onVerifyOTP = this.onVerifyOTP.bind(this);
		this.onResendOtp = this.onResendOtp.bind(this);
		this.onBackPress = this.onBackPress.bind(this);
		this.onPhonelHandleKeyPress = this.onPhonelHandleKeyPress.bind(this);
		this.onOTPHandleKeyPress = this.onOTPHandleKeyPress.bind(this);
		this.handleFormChanges = this.handleFormChanges.bind(this);
	}

	componentWillMount()
	{
		document.title = "StyleSend - Login";
		// this.init();
		this.offlineInit();
		if (this.props.app.user && this.props.app.user.token !== "")
		{
			this.props.navigate("/profile");
		}
	}

	componentDidUpdate() {
		//handling special case using setTimeout when targeting 0th box, it was not working by default
		if(this.state.focusOtpBox.focus===true && this.state.focusOtpBox.index===0) {
			this.otpRef.current.focusInput();
			setTimeout(() => this.otpRef.current.focusInput(0), 1);
		} else if(this.state.focusOtpBox.focus===true) {
			this.otpRef.current.focusInput(this.state.focusOtpBox.index);
		}
	}

	offlineInit() {

		let bundleId = "com.smb.styleade";
		let resData = {
			name: "Styleade", 
			content: [{"_id": "0","heading": "Welcome to styleade app", "body": {}}],
			//take this to env or config
			baseUrl: "https://prod.styleade.com", 
			// baseUrl: "http://localhost:3000",
			locations: []
		};

		localStorage.setItem("appData", JSON.stringify(resData));
		localStorage.setItem("bundle", bundleId);
		this.props.initializeApp(resData);

		this.setState
			({
				name: resData.name
			});
	}

	baseUrl()
	{
		return Store.getState().init.appData.baseUrl;
	}

	onPhoneLogin()
	{
		this.setState
		({
			showLoginOptions: false,
			showOtpBox: false,
			showEmailBox: true,
			showBackButton: true,
			phoneNumber: "",
			otp: ""
		}, () =>
		{
			this.phoneRef.current.focus();
		});
	}

	handleFormChanges(values) {
		console.log("valuess", values);
		if(values && values.phoneNumber) {
			this.setState
			({
				phoneNumber: values.phoneNumber
			});
		}
		if(values && values.countryCode) {
			this.setState
			({
				countryCode: values.countryCode
			});
		}
	}

	onOTPChange(otp)
	{
		let previous = this.state.otp;
		let focus = {focus: false, index: 0};

		if(otp.length < previous.length) {
			focus = {focus: true, index: otp.length}
		} else if(otp.length === previous.length) {
			otp = otp.slice(0,-1);
		}

		this.setState
		({
			otp: otp,
			focusOtpBox: focus
		});
	}

	async onFetchOtp(values) {
		if(this.state.otpSent || this.state.otpSending) {
			console.log("already sent or sending!");
			return;
		}
		this.setState({ otpSending: true });

		let endPoint = "dashboard/otp/send";

		let otpPayload =
		{
			countryCode: values.countryCode,
			phoneNumber: values.phoneNumber
		}

		let fetchOtpResponse = await HttpService.postMethod(endPoint, otpPayload);

		if (fetchOtpResponse.status === true)
		{
			this.setState
			({
				otpTimer: 120,
				otpSent: true,
				otpSending: false,
				focusOtpBox: {focus: true, index: 0}
			});
			message.success("OTP has been sent to your phone number");

			let timer = setInterval(() =>
			{
				if (this.state.otpTimer > 0)
				{
					this.setState({otpTimer: this.state.otpTimer - 1});
				}
				else
				{
					this.setState
					({
						isResendEnabled: true
					});

					clearInterval(timer);
				}

			}, 1000);
		}
		else
		{
			// console.log("failed");
			this.setState({otpSending: false});
			message.error("You don't have access to the administrator panel. Please contact the technical team for more information.");
		}
	}

	async onResendOtp()
	{
		if(!this.state.isResendEnabled) {
			return;
		}
		let endPoint = "dashboard/otp/resend";

		let otpPayload =
		{
			phoneNumber: this.state.phoneNumber,
			countryCode: this.state.countryCode
		}

		let fetchOtpResponse = await HttpService.postMethod(endPoint, otpPayload);

		if (fetchOtpResponse.status === true)
		{
			message.success("OTP has been sent to your phone number again");

			this.setState
			({
				otpTimer: 120,
				isResendEnabled: false
			});

			let timer = setInterval(() =>
			{
				if (this.state.otpTimer > 0)
				{
					this.setState({otpTimer: this.state.otpTimer - 1});
				}
				else
				{
					this.setState
					({
						isResendEnabled: true
					});

					clearInterval(timer);
				}

			}, 1000);
		}
		else
		{
			message.error("You don't have access to the administrator panel. Please contact the technical team for more information.");
		}
	}

	async onVerifyOTP()
	{
		if(this.state.otp <4) {
			return;
		}
		if (this.state.otp === "" || this.state.otp === null || this.state.otp === undefined)
		{
			this.setState
			({
				otpInfo: "block"
			});

			setTimeout(() =>
			{
				this.setState
				({
					otpInfo: "none"
				});

			}, 2000);

			return false;
		}

		let endPoint = "dashboard/otp/verify";

		//planSelected
		const urlParams = new URLSearchParams(window.location.search);

		let otpPayload =
		{
			countryCode: this.state.countryCode,
			phoneNumber: this.state.phoneNumber,
			otp: parseInt(this.state.otp),
			planSelected: urlParams.get('plan'),
			planCycle: urlParams.get('cycle')
		}

		let headers =
		{
			platform: "Browser",
			version: 0,
			"Content-Type": "application/json"
		}

		let fetchVerifyOtpResponse = await HttpService.postMethod(endPoint, otpPayload, headers);

		if (fetchVerifyOtpResponse.status === true)
		{
			let data = fetchVerifyOtpResponse.data;

			localStorage.setItem("user", JSON.stringify(data));

			let userPayload =
			{
				name: data.name,
				picture: data.picture,
				email: data.email,
				userId: data.userId,
				created: data.created,
				token: data.token,
				refresh: data.refresh,
				locationName: data.locationName,
				type: data.type,
				designation: data.designation,
				shopifyLinked: data.shopifyLinked
			}

			message.success("You have been logged in !!");
			// this.props.dispatch(login(userPayload));
			this.props.login(userPayload);
			this.props.navigate("/profile");
		}
		else if(fetchVerifyOtpResponse.status === false) {
			message.error(fetchVerifyOtpResponse.message);
		} else
		{
			message.error("You don't have access to the administrator panel. Please contact the technical team for more information.");
		}
	}

	onBackPress()
	{
		this.setState
		({
			showLoginOptions: true,
			showEmailBox: false,
			showOtpBox: false,
			showBackButton: false
		});
	}

	onPhonelHandleKeyPress(e) 
	{
		if (e.charCode === 13)
		{
			this.onFetchOtp();
		}
	};

	onOTPHandleKeyPress(e) 
	{
		if (e.charCode === 13)
		{
			this.onVerifyOTP();
		}
	};

	validatePhone(phone)
	{
		console.log("I am here!");

		const reg = /^[+]{1}?[(]?[0-9]{3}[)]?[0-9]{3}[0-9]{4,6}$/;
		return reg.test(phone);
	}

	onPhoneChange(e) {
		//console.log("E", e);
	}

	phoneNumberKeyboardHandle(event) {
        if(event.code==='ArrowDown' || event.code==='ArrowUp' || event.code==='KeyE' || 
          (this.state.phoneNumber.toString().length >= 12 && ['1','2','3','4','5','6','7','8','9','0'].includes(event.key))) {
            event.preventDefault();
        }
	}
	render()
	{

		let zoom;
		let width = window.innerWidth;

		if (width < 1400)
		{
			zoom = 0.72;
		}
		else if (width < 1600)
		{
			zoom = 0.84;
		}
		else if (width < 1920)
		{
			zoom = 0.95;
		}
		else
		{
			zoom = 1;
		}

		const { Option } = Select;

		const countryCodeSelector = (
		    <Form.Item name="countryCode" noStyle>
		      <Select style={{ width: '100px' }} className="countrycode_dropdown">
		        <Option value="91"><img src="/IN.svg" style={{width:'24px'}} alt="" /> <span style={{position: 'relative', top: '2px'}}>&nbsp;+91</span></Option>
		        <Option value="1" selected={true}><img src="/US.svg" style={{width:'24px'}} alt="" /> <span style={{position: 'relative', top: '2px'}}>&nbsp;+1</span></Option>
		      </Select>
		    </Form.Item>
		);

		return (
			<div className="container-fluid" id="login_page" style={{ zoom: 1 / zoom }}>
				<div className="container-xl p-0 row m-auto" style={{ zoom: zoom }}>
					<div className="col-md-12 login_logo">
						<img src={require('../images/login_logo.svg').default} />
					</div>
					<div className="col-md-12 login_logo mobile_logo">
						<img src={require('../images/styleade-svg.svg').default} />
					</div>

					<div className="col-md-6 p-0 image_banner">
						<h1>
							Clienteling <span style={{ whiteSpace: "nowrap" }}>made simple</span>
						</h1>
						<div className="tint"></div>
					</div>
					<div className="col-md-6 login_side_banner">
						<div className="col-md-10">
							<div className="login_list" style={{display: this.state.showLoginOptions ? "block" : "none"}}>
								<h1 style={{ fontWeight: "700", fontSize: "32px", lineHeight: "68px" }}>Welcome Back</h1>
								<h4 style={{ fontWeight: "500", textAlign: "center", fontSize: "26px", lineHeight: "40px" }}>Login</h4>
								<img src={require('../images/icons/login-google.svg').default} onClick={this.onGoogleLogin} />
								<img src={require('../images/icons/login-email.svg').default} onClick={this.onPhoneLogin} />
							</div>

							<div className="email_box phone_box" style={{display: this.state.showEmailBox ? "block" : "none"}}>
								<h1 style={{ fontWeight: "700", fontSize: "48px", lineHeight: "68px" }}>Login</h1>
								<h6 style={{fontWeight:700}}>Phone number</h6>
								<Form
							      name="send_top"
							      onFinish={this.onFetchOtp}
							      initialValues={{
							        countryCode: this.state.countryCode,
							      }}
							      onValuesChange={this.handleFormChanges}
							      scrollToFirstError
							      noValidate={true} //for html5 validation error also showing sometimes
							    >
									 
									<Space>
									<Form.Item
								        name="phoneNumber"
								        rules={[{ required: true, message: 'Please enter your phone number!', pattern: new RegExp(/^[1-9]\d{7,11}$/) }]}
								      >
										<InputNumber className="phone_input" ref={this.phoneRef} addonBefore={countryCodeSelector} placeholder="9833123456" 
											// onKeyPressCapture={this.onPhonelHandleKeyPress}
											// value={this.state.phoneNumber}
											style={{ width: '100%' }}
											onChange={this.onPhoneChange}
											type="number"
											controls={false}
											min="0"
											onKeyDown={(e) => this.phoneNumberKeyboardHandle(e)}
                            				keyboard={false}
                            				inputMode="numeric"
										 />
							        </Form.Item>
							        <Form.Item >
										<Button 
											className="send_otp_button" 
											htmlType="submit" 
											size="large" style={{backgroundColor: "#7469F5", float: "right", fontSize: "120%", color: this.state.otpSent ? "#000": "#478AEE"}} 
											type="primary" 
											disabled={this.state.otpSending || this.state.otpSent}>
											{this.state.otpSending ?  buttonLoaderDark : <></>}
											{!this.state.otpSent ? (this.state.otpSending ? "Sending" : "Send OTP") : "Sent!"}
										</Button>
									</Form.Item>
							        </Space>

						        </Form>
								<div className="info" style={{display:this.state.phoneNumberInfo, color: "red"}}>Enter a valid Phone Number</div>

							</div>
							<div className="email_otp_box" style={{display: this.state.showOtpBox ? "block" : "none", marginTop:"20px"}}>
								<h6><b>OTP</b></h6>
								<OtpInput inputStyle="otp_input_single" ref={this.otpRef} value={this.state.otp} 
								 onChange={this.onOTPChange}
								 isInputNum={true}
								 numInputs={4} placeholder="----" separator={<span>&nbsp;</span>} 
								// onKeyDown={(e) => console.log("e", e)} 
								 />
								
								<div className="login_gap" style={{marginTop: "15px"}}></div>
								<div className="info" style={{display:this.state.otpInfo, color: "red",}}>OTP mismatched</div>
								<div>
									<a  style={{color: this.state.isResendEnabled ? "#478AEE": "#979797", fontSize:"120%"}} href="#" onClick={this.onResendOtp}><b>Resend OTP</b>
										{this.state.otpTimer > 0 ? (" "+this.state.otpTimer.toString()+" seconds") : ""}</a>
								</div>
								<br />
								<Button type="submit" className="login_button" style={this.state.otp.length===4 ? 
									{backgroundColor: "#7831ed", color: "#fff", boxShadow: "0px 17px 41px rgba(120, 50, 237, 0.21)"} : {}}  size="large" onClick={this.onVerifyOTP}>Login</Button>
									

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			initializeApp,
			login,
			setBundleId
		},
		dispatch
	);


function mapStateToProps(state)
{
	return {
		app: state.app
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
