import React from "react";
import { Navigate,useNavigate,useParams } from "react-router-dom";
import { connect } from "react-redux";
import Structure from "./Structure";
import jwt_decode from "jwt-decode";

function ProtectedRoute({ Component, props }) {
  const navigate = useNavigate();
  const params = useParams();
  var decoded = {};
  // console.log("loc", localStorage);
		let userLocalStorage = localStorage.getItem("user");
		if (userLocalStorage === 'null' || userLocalStorage === null)
		{
			return <Navigate to="/logout" />;
		}else{
			userLocalStorage = JSON.parse(userLocalStorage);
		
			let token = userLocalStorage.token;
	
			decoded = jwt_decode(token);
		}

		const isExpired = (Math.round(Date.now() / 1000) - decoded.exp) > 0 ? true : false;

		if (isExpired)
		{
			return <Navigate to="/logout" />
		}
		else
		{
			let userLocalStorage = localStorage.getItem("user");
			userLocalStorage = JSON.parse(userLocalStorage);
			//if (this.props.user.token !== ""){}

		if (userLocalStorage.token !== ""){
						//props.navigate = navigate;
						return <Structure><Component {...props} navigate={navigate} params={params} /></Structure>
					}
					else
					{
						return <Navigate to="/" />
					}
		}
}

function mapStateToProps(state)
{

	return {
		user: state.app.user
	}
}

export default connect(mapStateToProps)(ProtectedRoute);