import produce from "immer";
import { initApp } from "../Actions/actionTypes";

const MASTER_BASE_URL = "https://prod.styleade.com";

// const MASTER_BASE_URL = "https://dev.styleade.com";

// const MASTER_BASE_URL = "https://selfridges.styleade.com";

//const MASTER_BASE_URL = window.location.origin;

//const MASTER_BASE_URL = "http://localhost:3000";

// const MASTER_BASE_URL = "https://c6ade6584fa8.ngrok.io"

const initialState =
{
    appData:
    {
        baseUrl: MASTER_BASE_URL
    }
};

if (localStorage.getItem("user"))
{
    initialState["appData"] = JSON.parse(localStorage.getItem("appData"));
}

export const initializeApp = (state = initialState, action) =>
    produce(state, (draft) =>
    {
        switch (action.type)
        {
            case initApp:
                {
                    draft["appData"] = action.payload;
                    break;
                }

            default:
                return draft;
        }
    });
