import React from "react";
import { Skeleton } from "antd";

const TablePageShimmer = () =>
{
    return (
        <div>
            <div className="announcement">
                <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: '35px', marginTop: '-90px' }}>
                    <Skeleton.Button style={{ width: 140, height: 42, marginRight: "20px" }} active size="large" shape="round" />
                    <Skeleton.Button style={{ width: 220, height: 42 }} active size="large" shape="round" />
                </div>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "25px", marginTop: "65px" }}>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-end" }}>
                        <Skeleton.Button style={{ width: 330, height: 40 }} active size="large" shape="round" />
                        <Skeleton.Button style={{ width: "180px", height: "20px", marginLeft: "40px", marginRight: "60px", marginBottom: "10px" }} active shape="round" />
                    </div>
                    <div>
                        <Skeleton.Button style={{ width: 48 }} active size="large" shape="round" />
                    </div>
                </div>
                <div>
                    <Skeleton.Button style={{ width: "87vw", height: "80vh" }} active size="large" shape="round" />
                </div>
            </div>
        </div>
    )
}

export default TablePageShimmer;