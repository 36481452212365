import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { logout } from "../Actions/Login";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { message } from "antd";

message.config({maxCount: 1});

let key = 'logout_message';

class Logout extends Component
{
    componentDidMount()
    {
        this.props.logout();

        if (localStorage.getItem("isLoggedOut") === null)
        {
            localStorage.setItem("isLoggedOut", 1);
            message.warning({content: "Logged out !!", key: key});
        }
    }
    render()
    {
        return <Navigate to="/" />
    }
}

const mapStateToProps = (state) =>
{
    return {

    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logout
        },
        dispatch
    );

const newLogout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);

export default newLogout;