import React, { Component } from "react";
import { Tabs, Skeleton, message, Modal, Form, Input, Row, Col, Button, Select, InputNumber } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserInfo } from "../../Actions/UserProfileAction";
import UsersList from "./UsersList";
import { changeNavAction } from "../../Actions/NavAction";
import HttpService from "../../Common/HttpService";
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const closeButtonStyle = {
    width: "36px",
    height: "36px",
    marginTop: "-40px",
    marginRight: "-10px"
}
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};

const buttonLoader = <LoadingOutlined style={{ fontSize: 28, color: "white", margin: "-8px 5px 0px 8px" }} spin />;
class Users extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: true,
            inviteModalVisible: false,
            countryCode: "91",
            inviteSuccess: false,
            inviteBtnLoading: false
        }
    }

    changePageTitle(e) 
    {
        switch(e)
        {
            case "1": document.title = "StyleSend - User Management"; break;
            case "2": document.title = "StyleSend - User Management"; break;
            case "3": document.title = "StyleSend - User Management"; break;
            case "4": document.title = "StyleSend - User Management"; break;
        }
    }

    toggleLoad = (status) =>
    {
        this.setState({ loading: status })
    }

    componentDidMount()
    {
        this.changePageTitle("1");
        this.props.fetchUserInfo(this.props.token);
        this.props.changeNavAction("usersManagement");
    }

    showInviteModal = () => {
        this.setState({inviteModalVisible: true});
    };

    inviteModalHandleOk = () => {
        this.setState({ inviteModalVisible: false, inviteSuccess: false });
    };

    inviteModalHandleCancel = () => {
        if(this.state.inviteSuccess===true) {
            window.location.reload();
        }
        this.setState({ inviteModalVisible: false, inviteSuccess: false });
    };

    onFinish = (values) => {
        this.setState({inviteBtnLoading: true});
        HttpService.post(this.props.token, "dashboard/user/invite", {
                fullName: values.fullName,
                countryCode: values.countryCode,
                email: values.email,
                phoneNumber: values.phoneNumber
            }).then(res => {
                if(!res.data.status) {
                    message.error(res.data.message);
                    this.setState({inviteBtnLoading: false});
                } else {
                    this.setState({inviteSuccess: true, inviteBtnLoading: false});
                }
            });
    }

    render()
    {

        const countryCodeSelector = (
            <Form.Item name="countryCode" noStyle>
              <Select style={{ width: 110 }} className="countrycode_dropdown">
               
                <Option value="91"><img src="/IN.svg" style={{width:'24px'}} alt="in" /> <span style={{position: 'relative', top: '2px'}}>&nbsp;+91</span></Option>
                 <Option value="1" selected={true}><img src="/US.svg" style={{width:'24px'}} alt="us" /> <span style={{position: 'relative', top: '2px'}}>&nbsp;+1</span></Option>
              </Select>
            </Form.Item>
        );

        return (
            <div className="page users-management" style={{paddingTop: '160px'}}>
                <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: '20px', marginTop: '17px' }}>
                    <div className="page-head-text-box">
                        <h1 className="page-head-text">
                            {
                                this.state.loading ?
                                    <Skeleton.Button active shape="round" style={{ width: "250px" }} />
                                    : "User Management"
                            }

                        </h1>
                    </div>
                </div>
                 <UsersList toggleLoad={this.toggleLoad} inviteModal={() => this.showInviteModal()} />
                 <Modal
                        key="inviteUser"
                        title={this.state.inviteSuccess ? "": "Invite User"}
                        visible={this.state.inviteModalVisible}
                        centered
                        onOk={this.inviteModalHandleOk}
                        onCancel={this.inviteModalHandleCancel}
                        destroyOnClose={true}
                        footer={null}
                        wrapClassNam="edit-organization"
                        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.28)" }}
                        width={800}
                        closeIcon={
                            <img src={require("../../images/icons/dark-close.png")} style={closeButtonStyle} />
                        }
                        >

                {this.state.inviteSuccess ? (
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <img src={require("../../images/icons/green-success.svg").default}

                        style={{ width: "50px", height: "50px", borderRadius: "50%", marginBottom: '18px', textAlign: 'center' }}
                        />
                        <h5 style={{ marginBottom: "35px", fontWeight: "800", marginTop: "35px" }}>Invitation sent successfully</h5>
                        
                        <Row>
                            <Col span={24} style={{ padding: "12px" }}>
                                <Button onClick={() => this.inviteModalHandleCancel() } className="invite-btn invite-cancel-button">Close</Button>
                            </Col>
                        </Row>
                    </div>
                ) : (
                <Form
                    className="invite-form styleade-form"
                    ref={this.formRef}
                    {...layout}
                    name="inviteUser"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    scrollToFirstError={true}
                    preserve={false}
                    initialValues={{
                        countryCode: this.state.countryCode,
                    }}
                    noValidate={true} //for html5 validation error also showing sometimes
                    >
                    <Form.Item
                        name="fullName"
                        label="Full name"
                        rules={[
                                {required: true, message: 'Please enter valid full name!', pattern: new RegExp(/^(?!\s*$)([a-z ,&.'-]{1,60})$/i)},
                                {min: 3, message: 'Please enter minimum 3 character!'},
                            ]}
                      >
                        <Input className="phone_input" placeholder="Enter full name" 
                            // onKeyPressCapture={this.onPhonelHandleKeyPress}
                            // value={this.state.phoneNumber}

                            style={{ width: '100%' }}
                     
                         />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email ID"
                        rules={[{required: true, message: 'Please enter valid email ID!', pattern: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i)}]}
                      >
                        <Input className="email_input" placeholder="Enter Email ID"
                            // onKeyPressCapture={this.onPhonelHandleKeyPress}
                            // value={this.state.phoneNumber}
                            type="email"
                            style={{ width: '100%' }}
                     
                         />
                    </Form.Item>

                    <Form.Item
                        name="phoneNumber"
                        className="phone_box"
                        label="Phone number"
                        rules={[{message: 'Please enter phone number!', pattern: new RegExp(/^[1-9]\d{7,11}$/) }]}
                      >
                        <InputNumber className="phone_input input_number" ref={this.phoneRef} addonBefore={countryCodeSelector} placeholder="Enter phone number" 
                            // onKeyPressCapture={this.onPhonelHandleKeyPress}
                            // value={this.state.phoneNumber}
                            style={{ width: '100%' }}
                            // onChange={this.onPhoneChange}
                            type="number"
                            min="0"
                            onKeyDown={(event) => {
                                if(event.code==='ArrowDown' || event.code==='ArrowUp' || event.code==='KeyE') {
                                    event.preventDefault();
                                }
                            }}
                            controls={false}
                            keyboard={false}

                            inputMode="numeric"
                         />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Row>
                            <Col span={24} style={{ padding: "12px" }}>
                                {this.state.inviteBtnLoading ? 
                                    <Button key="submit" className="invite-btn invite-submit-button" disabled htmlType="submit">
                                        {buttonLoader}
                                        Invite User
                                    </Button>
                                    :
                                    <Button key="submit" className="invite-btn invite-submit-button" htmlType="submit">
                                        Invite User
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
                )}
                 </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) =>
{
    return {
        token: state.app.user.token,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchUserInfo,
            changeNavAction
        },
        dispatch
    );


const newUsers = connect(
    mapStateToProps, mapDispatchToProps
)(Users);


export default newUsers;