import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { changeNavAction } from "../Actions/NavAction";
import { bindActionCreators } from "redux";

const style = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundImage: 'linear-gradient(to right, #987dfb, #FF6A95)',
	minHeight: '100vh',
}

const NotFound = (props) =>
{
	return (
		<div className="page" style={style} >
			<div>
				<h1 style={{
					color: 'white',
					letterSpacing: '12px',
					fontSize: '186px',
					fontWeight: '900',
					lineHeight: '90px'
				}}>404</h1>
				<p style={{
					color: 'white',
					fontSize: '22px',
					fontWeight: '700',
					padding: '8px 18%'
				}}>The link you have clicked might be broken or the page may have been removed.</p>
				<Link to="/dashboard/organization-settings">
					<button style={{
						width: '243px',
						height: '60px',
						backgroundColor: '#FFFFFF',
						borderRadius: '50px',
						border: 'none',
						fontSize: '17px', fontWeight: '800', color: '#987dfb'
					}} onClick={() =>
					{
						props.changeNavAction("organizationSettings");
					}}>
						Go to Dashboard
				</button>
				</Link>
			</div>
		</div>
	)

}


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			changeNavAction
		},
		dispatch
	);

export default connect(null, mapDispatchToProps)(NotFound);