import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from 'react-moment';
import { Checkbox, Button, message } from "antd";
import HttpService from "../Common/HttpService";
import { bindActionCreators } from "redux";
import PlainButton from "../Common/Button";
import { fetchUserInfo } from "../Actions/UserProfileAction";
import { Skeleton } from "antd";

const label = {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21.92px',
    color: '#9f9f9f'
}

const info = { textAlign: "left", padding: "40px 15px 0px 15px", clear: "left" }

const infoValue = {
    color: '#000000',
    fontWeight: 700,
    paddingTop: '8px'
}

const whatsappStatusMap = (key) => {
    switch(key) {
        case "not_registered":
            return {text: "Not registered", color: "red", check: false};
        break;
        case "documents_pending":
            return {text: "Documents pending", color: "#F49300", check: false};
        break;
        case "approval_pending":
            return {text: "Approval pending", color: "#F49300", check: false};
        break;
        case "registration_approved":
            return {text: "Registration approved", color: "#0EBE74", check: true};
        break;
        case "registration_rejected":
            return {text: "Registration rejected", color: "red", check: false};
        break;
        default:
            return {text: key, color: "#F49300"}
    }
}

class UserProfile extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loaded: false
        };
        document.title = "StyleSend - Profile";
    }
    componentDidMount()
    {
        console.log(this.props.token);
        
        this.props.fetchUserInfo(this.props.token, () =>
        {
            
            // redirect
            console.log("this.props.user.subscriptionExists", JSON.stringify(this.props.userInfo));
            if(!this.props.userInfo.organizationLinked && !this.props.userInfo.subscriptionExists) {
                console.log("will redirect here");
                window.location.href='/subscribe';
                return;
            }

            this.setState({ loaded: true });
        });
    }

    customerPortal()
    {
        this.setState({ loaded: false });
        HttpService.post(this.props.token, "dashboard/customer-portal", {}).then(res =>
        {
            if(res.data.status) {
                console.log("url", res.data.data.url);
                window.location.href = res.data.data.url;         
            } else {
                this.setState({ loaded: true });
                message.error("Something went wrong! Please try again later or contact support!");
            }
            
        }).catch(err =>
        {
            this.setState({ loaded: true });
            message.error(err);
        });
    }

    render()
    {
        const user = this.props.user;
        const userRoleToManageUser = ["Store Associate", "Manager"];

        return (
            <div className="page profile-page">
                {
                    this.state.loaded
                        ?
                        (
                            <div className="profile-container">
                                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "35px" }}>
                                    <div className="profile-wrapper" style={{position: "relative"}}>
                                        <img
                                            src={this.props.userInfo.picture === "" ? require("../images/user_default2.jpg") : this.props.userInfo.picture}
                                            style={{
                                                borderRadius: "50%",
                                                marginRight: "15px",
                                                filter: "drop-shadow(0px 20px 15px rgba(154,161,169,0.4))",
                                                height: "100px",
                                                width: "100px"
                                            }}
                                            onError={(e) =>
                                            {
                                                e.target.src = require("../images/user_default2.jpg")
                                            }}
                                        />
                                       {this.props.userInfo.picture === "" ? (<span className="initial" style={{position: "absolute", color: 'white', fontSize: '48px', width: '100px', top: "34px", left: '0', lineHeight: '48px'}}>{user.name.charAt(0)}</span>) : (<></>)} 
                                    </div>
                                    <div style={{ padding: "12px", textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            <h4 className="profile-name">{user.name} &nbsp;<a href="profile-edit" style={{fontSize: '16px'}}>Edit</a></h4>
                                            <p style={{ margin: "0px" }}>{this.props.userInfo.organization}</p>
                                        </div>
                                    </div>
                                </div>
                               
                                <div style={info}>
                                    <h6 style={{...label, marginBottom: '16px'}}>Phone number</h6>
                                    <h6 style={{...infoValue, marginBottom: '8px'}}>{this.props.userInfo.phoneNumber}</h6>
                                </div>

                                <div style={info}>
                                    <h6 style={label}>Download the StyleSend Mobile App</h6>
                                    {this.props.userInfo.loggedOnMobile ? 
                                        (<div>
                                            <img src="/green-tick.svg" style={{float: "left", padding: '5px 10px 5px 0px'}} />
                                            <p className="info-text info-text-green" style={{marginBottom: '0px'}} size="large">Installed</p>
                                        </div>) : 
                                        (
                                        <>
                                            <a href="#">
                                                <img src={require("../images/app-store-widget.svg").default} alt="Download from Apple App Store"
                                                style={{marginTop: '15px'}} />
                                                <img src={require("../images/play-store-widget.svg").default} alt="Download from Google Play Store"
                                                style={{marginLeft: '12px', marginTop: '15px'}} />
                                            </a>
                                        </>)}
                                </div>

                                {(!this.props.userInfo.shopifyInstalled) ? (
                                        <div style={info}>
                                            <h6 style={label}>Shopify Plugin / Unique Organization Code</h6>
                                            {(<Button className="info-btn" size="large">Install Shopify Plugin</Button>)}
                                        </div>
                                    ) : (
                                    <>
                                        <div style={info}>
                                            <h6 style={label}>Shopify Plugin</h6>
                                            {this.props.userInfo.shopifyInstalled ? 
                                                (<div>
                                                    <img src="/green-tick.svg" style={{float: "left", padding: '5px 10px 5px 0px'}} />
                                                    <p className="info-text info-text-green" size="large" style={{marginBottom: '0px'}}>Installed</p>
                                                </div>) : 
                                                (<Button className="info-btn" size="large">Install Shopify Plugin</Button>)}
                                            {this.props.userInfo.shopifyInstalled ? (<p className="shopify-status" style={{fontWeight: 400, fontSize: '18px', lineHeight: '22px', marginBottom: '8px', marginTop: '16px'}}>Data last refreshed at <Moment format="h:mma on Do MMMM, YYYY" date={this.props.userInfo.shopifyLatestSyncDate}></Moment></p>) : (<></>)}
                                        </div>
                                        <div style={info}>
                                            <h6 style={label}>Unique organization code</h6>
                                            {this.props.userInfo.organizationCode ? 
                                                (<p className="info-text" style={{color: '#000', marginBottom: '0px'}} size="large">{this.props.userInfo.organizationCode}</p>) : 
                                                (<Button className="info-btn" size="large">Install Shopify Plugin</Button>)}
                                        </div>
                                    </>
                                )}
                                
                                <div style={info} className="whatsapp-info">
                                    <h6 style={label}>WhatsApp Business API</h6>

                                    {whatsappStatusMap(this.props.userInfo.whatsappStatus).check ? 
                                        ( <img src="/green-tick.svg" style={{float: "left", padding: '12px 10px 5px 0px'}} />) : (<></>)}

                                    <p className="info-text" size="large" style={{color: whatsappStatusMap(this.props.userInfo.whatsappStatus).color, marginBottom: '0px'}}>
                                        {whatsappStatusMap(this.props.userInfo.whatsappStatus).text}
                                    </p>
                                </div>

                                <div style={info}>
                                    <h6 style={{...label, marginBottom: '8px'}}>Subscription plan</h6>
                                    {this.props.userInfo.subscriptionExists ? (
                                    <>
                                        <h2 style={{fontWeight:700, paddingTop:'16px', textTransform: 'capitalize', marginBottom: '6px'}}>{this.props.userInfo.planDetails['name']} <span>{!this.props.userInfo.subscriptionActive ? "(Past due)" : ""}</span></h2>
                                        <p className="" style={{fontSize:'18px', color:'#000', paddingTop: '16px', marginBottom: '8px'}} size="large">$ {this.props.userInfo.planDetails['price']} / {(this.props.userInfo.planDetails['priceCycle']==="annually") ? "year" : "month"}</p>
                                    </>) : (<></>)}
                                    {/* <Button className="info-btn" size="large" onClick={() => {this.props.navigate("/renew"); window.scrollTo(0, 0);}}>Renew subscription</Button>
                                    <Button className="info-btn upgrade-btn" onClick={() => {this.props.navigate("/purchase"); window.scrollTo(0, 0);}} style={{border: 'none', backgroundColor: 'none', textDecorationLine: 'underline'}} size="large">Change plan</Button>*/}
                                    
                                    { userRoleToManageUser.includes(user.designation) ? (!this.props.userInfo.subscriptionExists ? 
                                        (<Button className="info-btn" size="large" onClick={() => {window.location.href = '/subscribe'; window.scrollTo(0, 0);}}>Restart subscription</Button>) 
                                        : (<Button className="info-btn" size="large" onClick={() => {this.customerPortal(); window.scrollTo(0, 0);}}>Manage Your Subscription</Button>)) : (<></>)}
                                </div>
                                <br />
                                <br />
                                
                            </div>
                        )
                        :
                        (
                            <div style={{ padding: "50px" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "65px" }}>
                                    <Skeleton.Avatar active size={100} />
                                    <div style={{ padding: "12px", textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            <h4><Skeleton.Input style={{ width: 250 }} active size="large" /></h4>
                                            <p style={{ margin: "0px" }}><Skeleton.Input style={{ width: 150 }} active size="small" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={{ ...info, display: "flex", alignItems: "center" }}>
                                    <Skeleton.Avatar active shape="square" style={{ marginRight: "20px" }} />
                                    <Skeleton.Input style={{ width: 150 }} active size="small" />
                                </div>
                                <div style={info}>
                                    <Skeleton.Button style={{ width: "230px" }} active size="large" shape="round" />
                                </div>
                            </div>
                        )
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchUserInfo
        },
        dispatch
    );

const mapStateToProps = (state) =>
{
    return {
        user: state.app.user,
        token: state.app.user.token,
        userInfo: state.userInfo.userInfo
    };
};

const newUserProfile = connect(
    mapStateToProps, mapDispatchToProps
)(UserProfile);

export default newUserProfile;