import React, { Component } from "react";
import ReportButtons from "../../Common/ReportButtons";
import { connect } from "react-redux";
import DateFilter from "../../Common/DateFilter";
import SearchBar from "../../Common/SearchBar";
import HttpService from "../../Common/HttpService";
import { Table, Empty, Tag, message, Popover, Button, Typography, Checkbox, Avatar, Select, Modal, Row, Col } from "antd";
import Moment from 'react-moment';
import { debounce } from "lodash";
import moment from "moment";
import DownloadLoader from "../../Common/DownloadLoader";
import TablePageShimmer from "../../Common/TablePageShimmer";
import UsersManagementFilterContent from "./UsersManagementFilterContent";
//import { withRouter } from "react-router";
import { DownOutlined, UpOutlined, DeleteOutlined, StopOutlined, LoadingOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

const { Option } = Select;

const closeButtonStyle = {
    width: "36px",
    height: "36px",
    marginTop: "-40px",
    marginRight: "-10px"
}

const statusStyleMapping = {
    "active": {
        "bg": "#d4fceb",
        "color": "#007A46",
        "text": "JOINED"
    },
    "deleted": {
        "bg": "#f7f7f7",
        "color": "#a3a3a4",
        "text": "DELETED"
    },
    "invited": {
        "bg": "#fcf8d4",
        "color": "#795f00",
        "text": "INVITED"
    }
}
const buttonLoader = <LoadingOutlined style={{ fontSize: 28, color: "white", margin: "-8px 5px 0px 8px" }} spin />;
class UsersList extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            dataSource: [],
            availableFilters: {},
            totalResults: "",
            search: "",
            start: 0,
            length: 10,
            prevSize: 10,
            sort: "",
            order: "",
            filter: {},
            downloadFlag: false,
            showReportButtons: true,
            shimmerLoad: false,

            visible: false,
            deleteModalVisible: false,
            pendingCount: 0,
            deleteRecord: {},
            deleteBtnLoading: false
        }
    }

    componentDidMount()
    {
        this.fetchUsers();
    }

    changeLoadingTrue = () =>
    {
        // this.props.toggleLoad(true);
        this.setState({ loading: true });
    };

    changeLoadingFalse = () =>
    {
        // this.props.toggleLoad(false);
        this.setState({ loading: false });
    };

    fetchUsers = () =>
    {
        this.changeLoadingTrue();
        this.setState({ showReportButtons: false }, () =>
        {
            HttpService.post(this.props.token, "dashboard/users/all", {
                search: this.state.search,
                start: this.state.start,
                length: this.state.length,
                sort: this.state.sort,
                order: this.state.order,
                filter: this.state.filter
            }).then(res =>
            {
                console.log(res);
                if (res.data.status)
                {
                    if (!res.data.data.results.length)
                    {
                        message.info("No data avaiable for the filter");
                    }

                    const unique = res.data.data.unique;


                    const availableFilters = {
                        //bucket: bucket,
                        //storeId: storeId
                    }

                    this.setState({
                        dataSource: res.data.data.results,
                        totalResults: res.data.data.totalResults,
                        availableFilters: availableFilters,
                        shimmerLoad: true,
                        pendingCount: res.data.data.pendingCount

                    }, () =>
                    {
                        console.log(this.state);
                        this.props.toggleLoad(false);
                        this.changeLoadingFalse();
                    })
                }
                else
                {
                    this.setState({
                        dataSource: [],
                    }, () =>
                    {
                        this.changeLoadingFalse();
                    });
                }
            }).catch(err =>
            {
                this.changeLoadingFalse();
                message.error(err);
            });
        });
    }

    handleTableChange = (pagination, filters, sorter) =>
    {
        console.log("pagination", pagination);
        const sort = sorter.length === undefined
            ?
            { sort: sorter.field, order: sorter.order === undefined ? 0 : sorter.order === "ascend" ? 1 : -1 }
            :
            { sort: sorter[sorter.length - 1].field, order: sorter[sorter.length - 1].order === undefined ? 0 : sorter[sorter.length - 1].order === "ascend" ? 1 : -1 }

        this.setState({
            prevSize: this.state.length
        }, () =>
        {
            // console.log(this.state.prevSize * (pagination.current - 1));
            this.setState({
                totalResults: pagination.total,
                length: pagination.pageSize,
                start: this.state.prevSize * (pagination.current - 1),
                sort: sort.order === 0 ? "" : sort.sort,
                order: sort.order === 0 ? "" : sort.order,
            }, () =>
            {
                this.fetchUsers();
            })
        });
    }

    setDateFilter = (start, end) =>
    {
        const filter = this.state.filter;

        const endDate = end !== "" ? moment(end, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD") : "";

        filter["from"] = start;
        filter["to"] = end;

        this.setFilter(filter);
    }

    setFilter = (filterObj) =>
    {
        const from = this.state.filter.from === undefined ? "" : this.state.filter.from;
        const to = this.state.filter.to === undefined ? "" : this.state.filter.to;

        filterObj['from'] = from;
        filterObj['to'] = to;

        this.setState({ filter: filterObj, start: 0 }, () =>
        {
            this.fetchUsers();
        })
    }


    searchRequest = debounce(() =>
    {
        this.fetchUsers();
    }, 500);

    setSearchText = (text) =>
    {
        this.setState({
            search: text,
            start: 0
        }, () =>
        {
            this.searchRequest()
        })
    }


    shareReport = () =>
    {
        message.info("Sharing Report ...");
        this.setState({ downloadFlag: true });
        HttpService.post(this.props.token, "admin/users/all", {
            length: this.state.totalResults,
            search: this.state.search,
            sort: this.state.sort,
            order: this.state.order,
            start: 0,
            filter: this.state.filter

        }).then((res) =>
        {
            if (res.data.status)
            {
                const subject = "Users";
                const body = "Hi there, %0D%0A%0D%0AThe latest staff management link is shared below. %0D%0APlease visit the link ";
                const fileName = res.data.data.file + ".csv";
                const reportLink = this.props.baseUrl + "/image/" + fileName;


                window.open("mailto:?subject=" + subject + "&body=" + `${body + reportLink}`);
                this.setState({ downloadFlag: false });

            }
        }).catch(() =>
        {
            message.error("Error in Sharing")
        });

    }

    fetchReport = () =>
    {
        message.info("Report Downloading ......");
        this.setState({ downloadFlag: true });
        HttpService.post(this.props.token, "admin/users/all", {
            length: this.state.totalResults,
            search: this.state.search,
            sort: this.state.sort,
            order: this.state.order,
            start: 0,
            filter: this.state.filter

        }).then((res) =>
        {
            const fileName = res.data.data.file + ".csv";
            const reportLink = this.props.baseUrl + "/image/" + fileName;

            window.open(reportLink, "_blank");

            this.setState({ downloadFlag: false });
        }).catch(() =>
        {
            message.error("Error in Downloading");
        })
    }

    filterHide = () =>
    {
        this.setState({
            visible: false,
        });
    };

    changeFilterVisible = state =>
    {
        this.setState({ visible: state });
    };


    handleTableExpandClick = (expanded, onExpand, record, e) => {
        let width = window.innerWidth;
        if (width > 768) {
            e.preventDefault();
        } else {
            onExpand(record, e);
        }
    }

    deleteHandle = (record) => {
        this.setState({deleteModalVisible: true, deleteRecord: record});
    }

    deleteButton = (record, customStyles) => {
        if(record.status==="invited" || record.status==="deleted") {
            return (<Button shape="round" size="large" style={{float: "none", border: "none", boxShadow: "none", backgroundColor: 'rgb(247, 247, 247)', color: 'rgb(163, 163, 164)', ...customStyles}}>
                        <StopOutlined /> <span style={{position: 'relative', top: '2px'}}>Delete user</span>
                    </Button>);
        }
        return (
                    <Button shape="round" onClick={() => this.deleteHandle(record)} size="large" style={{float: "none", border: "none", boxShadow: "none", ...customStyles}}>
                        <img src={require('../../images/icons/delete-svg.svg').default} /> <span style={{paddingLeft: '14px', position: 'relative', top: '2px'}}>Delete user</span>
                    </Button>
                );
    }

    deleteHandleSubmit = (record) => {
        this.setState({deleteBtnLoading: true});
        console.log("rc", record);
        HttpService.post(this.props.token, "dashboard/user/delete", {
                tId: record.userId
            }).then(res => {
                if(!res.data.status) {
                    message.error(res.data.message);
                } else {
                    message.success(res.data.message);
                    this.setState({deleteModalVisible: false, deleteBtnLoading: false});
                    this.fetchUsers();
                }
            });

        if(this.state.deleteBtnLoading) { this.setState({deleteBtnLoading: false}); };
    }

    statusStyleRender = (value) => {
        if(statusStyleMapping[value]) {
            return <span className="status_btn" style={{backgroundColor: statusStyleMapping[value]['bg'], color: statusStyleMapping[value]['color']}}>{statusStyleMapping[value]['text']}</span>
        } else {
            return <span className="status_btn" style={{backgroundColor: statusStyleMapping['invited']['bg'], color: statusStyleMapping['invited']['color']}}>{value}</span>
        }
    }

    render()
    {

        let start = this.state.start === 0 ? 1 : (this.state.start / this.state.length) * this.state.length + 1;
        const UsersList = [...this.state.dataSource]

        UsersList.forEach(item =>
        {
            item['id'] = start++;
            item['login'] = {
                google: item.googleId !== "" ? true : false,
                yammer: item.yammerId !== "" ? true : false
            }

        })

        const columns = [
            {
                title: "No",
                dataIndex: "id",
                key: "id",
                render: (val) =>
                {
                    return <span className="cell-text">{val})</span>
                },
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                width: "12%",
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    sortDirections: ['ascend', 'descend'],

                },
            },
            {
                title: "Number",
                dataIndex: "phoneNumber",
                key: "phoneNumber",
                // sorter: {
                //     compare: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
                //     sortDirections: ['ascend', 'descend'],

                // },
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
                sorter: {
                    compare: (a, b) => a.email.localeCompare(b.email),
                    sortDirections: ['ascend', 'descend'],

                },
                responsive: ['md'],
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: {
                    compare: (a, b) => a.status.localeCompare(b.status),
                    sortDirections: ['ascend', 'descend'],

                },
                responsive: ['md'],
                render: (value) =>
                {
                    return this.statusStyleRender(value);
                }
            },
            {
                title: "Last Activity",
                dataIndex: "lastActivity",
                key: "lastActivity",
                // sorter: {
                //     compare: (a, b) => a.created.getTime() - b.created.getTime(),
                //     sortDirections: ['ascend', 'descend'],

                // },
                sorter: {
                    sortDirections: ['ascend', 'descend'],
                },
                responsive: ['md'],
                render: (value) =>
                {
                    return (value) ? <Moment format="Do MMMM YYYY, h:mm a" date={value}></Moment> : "-";
                }
            },
            Table.EXPAND_COLUMN
        ]


        return (
            <>
                {
                    this.state.downloadFlag ?
                        <DownloadLoader />
                        : null
                }
                {
                    this.state.shimmerLoad
                        ?
                        (
                        <>
                            <div className="users_list">
                                <div className="page_options" style={{ display: 'flex', justifyContent: "flex-end", marginBottom: '35px', marginTop: '-68px' }}>
                                    < div >

                                        <Button
                                            type="primary"
                                            className="invite_btn pending_btn"
                                            shape="round"
                                            style={{ backgroundColor: "transparent", boxShadow: "none", cursor: "inherit", color: "rgb(106, 16, 245)", marginRight: "25px", border: "none", paddingTop: "13px", paddingLeft: "34px", fontWeight: 500 }}
                                            // onClick={() => this.props.inviteModal()}
                                            >
                                            Pending <span style={{color: '#000', paddingLeft: '8px', fontWeight: 700}}>{this.state.pendingCount}</span>
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="invite_btn"
                                            shape="round"
                                            icon={<span className="plusIcon"><img src={require('../../images/ic_baseline-add.svg').default} style={{ height: "22px", width: "22px" }} /></span>
                                            } style={{ backgroundColor: "rgb(106, 16, 245)" }}
                                            onClick={() => this.props.inviteModal()}>
                                            Invite user
                                        </Button>
                                    </div >
                                </div >
                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "35px" }}>
                                </div>
                                <Table
                                    columns={columns}
                                    dataSource={!this.state.loading && UsersList}
                                    pagination={{
                                        // below current key can be removed if pagination causes any error
                                        current: Math.ceil((this.state.start / this.state.length) + 1),
                                        pageSize: this.state.length,
                                        total: this.state.totalResults,
                                        showSizeChanger: true,
                                        showTotal: () => <div style={{ position: "absolute", left: "0", marginLeft: "40px" }}>Total Results: {this.state.totalResults}</div>
                                    }}
                                    loading={this.state.loading}
                                    rowKey="id"
                                    onChange={this.handleTableChange}
                                    scroll={{ x: 'auto' }}
                                    locale={{
                                        emptyText:
                                            <Empty
                                                description={
                                                    <span>
                                                        {UsersList.length ? "" : "No Data"}
                                                    </span>
                                                }
                                                style=
                                                {{ height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                            />
                                    }}
                                    expandIcon={({ expanded, onExpand, record }) =>
                                      expanded ? (
                                        <UpOutlined
                                          style={{ float: "right" }}
                                          onClick={e => this.handleTableExpandClick(expanded, onExpand, record, e)}
                                        />
                                      ) : (
                                      <Popover placement="bottom" content={this.deleteButton(record, {})} trigger="click">
                                        <DownOutlined onClick={e => this.handleTableExpandClick(expanded, onExpand, record, e)} />
                                      </Popover>
                                      )
                                    }

                                    expandable={{
                                      expandedRowRender: (record) => { 
                                        
                                        return (
                                            <div>
                                                <p style={{textAlign: "center"}}><b>{record.email}</b> &nbsp; &nbsp; &nbsp; {this.statusStyleRender(record.status)}</p>
                                                <p style={{textAlign: "center", marginTop: "25px"}}>
                                                    Last activity: {record.lastActivity ? (<Moment format="Do MMMM YYYY, h:mm a" date={record.lastActivity}>)</Moment>) : "NA"}
                                                </p>
                                                <div style={{textAlign: "center", marginTop: "25px"}}> {this.deleteButton(record, {textAlign: "center", color: "#7832ED", backgroundColor: "#F6F1FE"})} </div>
                                            </div>)}
                                    }}
                                />
                            </div >
                        <Modal
                        key="deleteUser"
                       
                        visible={this.state.deleteModalVisible}
                        onOk={this.deleteModalHandleOk}
                        onCancel={() =>
                                    {
                                        this.setState({
                                            deleteModalVisible: false, deleteRecord: {}
                                        })
                                    }}
                        destroyOnClose={true}
                        footer={null}
                        wrapClassName="edit-organization"
                        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.28)" }}
                        width={800}
                        closeIcon={
                            <img src={require("../../images/icons/dark-close.png")} style={closeButtonStyle} />
                        }
                        >
                        
                                        <div style={{width: '100%', textAlign: 'center'}}>
                                            <img src={require("../../images/del-icon.svg").default}

                                        style={{ width: "50px", height: "50px", borderRadius: "50%", marginBottom: '18px', textAlign: 'center' }}
                                        />
                                        <h5 style={{ marginBottom: "45px", fontWeight: "800", marginTop: "30px" }}>Delete user</h5>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12} style={{ padding: "12px" }}>
                                            <Button onClick={() =>
                                            {
                                                this.setState({
                                                    deleteModalVisible: false, deleteRecord: {}
                                                })
                                            }} className="invite-btn invite-cancel-button cancel">Cancel</Button>
                                            </Col>
                                            <Col span={12} style={{ padding: "12px" }}>
                                            <Button
                                                
                                                onClick={() =>
                                                {
                                                    this.deleteHandleSubmit(this.state.deleteRecord);
                                                }}
                                                className="invite-btn logout">
                                                {this.state.deleteBtnLoading ?  buttonLoader : <></>}
                                                Delete User
                                            </Button>
                                            </Col>
                                        </Row>
                                </div>

                         </Modal>
                         </>
                        )
                        :
                        (
                            <TablePageShimmer />
                        )
                }


            </>
        )
    }
}

const mapStateToProps = (state) =>
{
    return {
        token: state.app.user.token,
        userId: state.app.user.userId,
        baseUrl: state.init.appData.baseUrl
    };
};


const newUsersList = connect(
    mapStateToProps
)(UsersList);

export default newUsersList;