import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

var firebaseConfig =
{
	apiKey: "AIzaSyAIXTNns52rRjKGBNsytq-mvEqADYZH3_s",
	authDomain: "styleade.firebaseapp.com",
	databaseURL: "https://styleade.firebaseio.com",
	projectId: "styleade",
	storageBucket: "styleade.appspot.com",
	messagingSenderId: "32465567518",
	appId: "1:32465567518:web:c255d0fc32a4be4741930f",
	measurementId: "G-12EJHC2CC4"
};

const firebaseClient = firebase.initializeApp(firebaseConfig);
const firebaseAnalyticsClient = firebase.analytics();

// firebaseAnalyticsClient.logEvent("page_view");

export default firebaseClient;