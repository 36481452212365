import produce from "immer";
import types from "../Actions/types";

let defaultState =
{
	bundle: "",
	user:
	{
		token: "",
		refresh: "",
		name: "",
		email: "",
		picture: "",
		userId: "",
		created: ""
	}
};

if (localStorage.getItem("user"))
{
	defaultState["user"] = JSON.parse(localStorage.getItem("user"));

}

if (localStorage.getItem("bundle"))
{
	defaultState["bundle"] = localStorage.getItem("bundle");

}


function reducer(state = defaultState, action)
{
	return produce(state, function (draft)
	{
		switch (action.type)
		{
			case types.LOGIN:
				{
					draft["user"] = action.payload;

					localStorage.removeItem("isLoggedOut");

					break;
				}
			case types.LOGOUT:
				{
					draft["user"] = null;

					let localStorageKeys = ["bundle", "appData", "user"];
					localStorageKeys.map((key) => localStorage.removeItem(key));

					break;
				}
			case types.SETBUNDLE:
				{
					draft["bundle"] = action.payload.bundleId
				}
			default:
				return draft;
		}

		return (draft);
	});
}

export default reducer;
