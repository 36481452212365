import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from 'react-moment';
import HttpService from "../../Common/HttpService";
import { Checkbox, Button, Row, Col, Radio, Switch, Input, Form, Select, InputNumber, message } from "antd";

import { bindActionCreators } from "redux";
import PlainButton from "../../Common/Button";
import { fetchUserInfo } from "../../Actions/UserProfileAction";
import { Skeleton } from "antd";

const { Option } = Select;

const label = {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21.92px',
    color: '#9f9f9f'
}

const info = { textAlign: "left", padding: "55px 15px 0px 15px", clear: "left" }

const infoValue = {
    color: '#000000',
    fontWeight: 700,
    paddingTop: '8px'
}

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};

class SubscribeComplete extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loaded: false,
            activeTab: "3",
            paymentData: {}
            // sessionId: props.params.id
        }
    }
    componentDidMount()
    {    
        this.fetchCheckoutInfo();

        document.title = "StyleSend - Complete";
        // window.location.hash = "#done";
        window.addEventListener("hashchange", e => this.handleHashChange());
    }

    fetchCheckoutInfo = () =>
    {
        this.setState({ loaded: false });
        HttpService.get("dashboard/checkout/summary", this.props.token).then(res =>
        {
            if(res.data.status) {
                this.setState({ loaded: true, paymentData: res.data.data });            
            } else {
                this.setState({ loaded: true });
                message.error("Something went wrong! Please try again later or contact support!");
            }
            
        }).catch(err =>
        {
            this.setState({ loaded: true });
            message.error(err);
        });

    }

    handleHashChange() {
        console.log("changed", window.location.hash);
    }

    render()
    {
        const user = this.props.user;
        return (    
        <Row>
            <Col sm={24} md={24}>        
                <div className="page purchase-page">
                    
                    {
                        this.state.loaded
                            ?
                            (
                                
                                    <div className="page-container purchase-container">
                                        
                                       <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: '20px', marginTop: '50px' }}>
                                            <div className="page-head-text-box">
                                                <h1 className="page-head-text">{this.state.paymentData.subscriptionStatus=="trialing" ? 'Free trial is active' : 'Payment complete'}</h1>
                                            </div>
                                        </div>
                                        <div className="purchaseTabs" style={{paddingTop: '30px'}}>
                                                <Button
                                                    type="primary"
                                                    className={"purchaseTab_btn "+( (this.state.activeTab==="1") ? " active":"")}
                                                    shape="round"
                                                    icon={<span className="plusIcon"><img src={require('../../images/icons/ellipse70-white.svg').default} /></span>
                                                    }
                                                    // onClick={() => this.setState({activeTab: "1"})}
                                                    >
                                                    Personal info
                                                </Button>
                                                <hr className="horizontalSep"/>
                                                <Button
                                                    type="primary"
                                                    className={"purchaseTab_btn "+( (this.state.activeTab==="2") ? " active":"")}
                                                    shape="round"
                                                    icon={<span className="plusIcon"><img src={require('../../images/icons/ellipse70-white.svg').default} /></span>
                                                    }
                                                    // onClick={() => this.setState({activeTab: "2"})}
                                                    >
                                                    Payment
                                                </Button>
                                                <hr className="horizontalSep"/>
                                                <Button
                                                    type="primary"
                                                    className={"purchaseTab_btn "+( (this.state.activeTab==="3") ? " active":"")}
                                                    shape="round"
                                                    icon={<span className="plusIcon"><img src={require('../../images/icons/ellipse70.svg').default} /></span>
                                                    }
                                                    // onClick={}
                                                    >
                                                    Purchase Complete
                                                </Button>
                                        </div>
                                      

                                        {this.state.activeTab==="3" ? (
                                            <>
                                            <Row>
                                                
                                                <Col sm={24} md={12} className="SubscribeCompleteCol" style={{position: "static"}}>
                                                    {
                                                    this.state.loaded ? (
                                                    <div className="SubscribeComplete" style={{textAlign: "left", marginTop: "54px", paddingLeft: "0"}}>
                                                        <h3><span className="planTitle" style={{fontSize: "28px", fontWeight: "700"}}>{this.state.paymentData.planName}</span></h3>
                                                        <div>
                                                            <p style={{paddingTop: '12px'}}>{this.state.paymentData.planTagline}</p>
                                                            <Row>
                                                                <Col>
                                                                    <p><span className="priceHighlight">${this.state.paymentData.subscriptionStatus==="trialing" ? 0.00 : (this.state.paymentData.total/100).toFixed(2)}</span></p>
                                                                </Col>
                                                                {/*<Col>
                                                                    <p style={{paddingLeft: '15px'}}><span> per month <br/> paid {this.state.paymentData.planDuration} </span></p>
                                                                </Col>*/}
                                                            </Row>
                                                            <p style={{paddingTop: '12px'}}>{this.state.paymentData.subscriptionStatus==="trialing" ? (<>Your free trial will be active till <Moment format="Do MMMM, YYYY"  unix>{this.state.paymentData.trialEnd}</Moment></>) : (<>Your plan will be active till <Moment format="Do MMMM, YYYY" unix>{this.state.paymentData.planEnd}</Moment></>)}</p>     
                                                        </div>
                                                        <div className={"planSelectBox"} style={{textAlign: "left", width: "auto", marginTop: '37px', paddingTop: '32px', maxWidth: '380px'}}>
                                                            <div className="">
                                                                <p>Info</p>
                                                                <Row>
                                                                    <Col span={12}>
                                                                        <p><span style={{fontSize: '20px', fontWeight: '700'}}>{this.state.paymentData.planName} Subscription</span></p>
                                                                    </Col>
                                                                    <Col style={{textAlign: 'right'}} span={12}>
                                                                        <p><span className="priceHighlight" style={{fontSize: '28px'}}>${(this.state.paymentData.total/100).toFixed(2)}</span></p>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{marginTop: '6px'}}>
                                                                    <Col span={12}>
                                                                        <p><span style={{fontSize: '20px', fontWeight: '700'}}>Tax</span></p>
                                                                    </Col>
                                                                    <Col style={{textAlign: 'right'}} span={12}>
                                                                        <p><span className="priceHighlight" style={{fontSize: '18px'}}>${this.state.paymentData.tax}</span></p>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{marginTop: '6px', borderTop: 'solid 1px #dcdcdc', paddingTop: '16px'}}>
                                                                    <Col span={12}>
                                                                        <p><span style={{fontSize: '20px', fontWeight: '700'}}>Total</span></p>
                                                                    </Col>
                                                                    <Col style={{textAlign: 'right'}} span={12}>
                                                                        <p><span className="priceHighlight" style={{fontSize: '18px'}}>${(this.state.paymentData.total/100).toFixed(2)}</span></p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        <Form.Item {...tailLayout}>
                                                            <Row>
                                                                <Col span={16} style={{ padding: "12px" }}>
                                                                    <Button key="submit" className="info-submit-button invite-btn invite-submit-button" style={{backgroundColor: "#7832ED", marginTop: '60px', boxShadow: '0px 17px 41px rgba(120, 50, 237, 0.21)'}} htmlType="submit"
                                                                        onClick={() => window.location.href='/profile'}
                                                                        >
                                                                        Go to homepage
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    </div>
                                                    ) : <></>
                                                    }
                                                </Col>
                                                
                                            </Row>
                                            
                                            </>
                                        ) : <></>}

                                       
                                    </div>
                       
                            )
                            :
                            (
                                <div style={{ padding: "50px" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "65px" }}>
                                        <Skeleton.Avatar active size={100} />
                                        <div style={{ padding: "12px", textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <div>
                                                <h4><Skeleton.Input style={{ width: 250 }} active size="large" /></h4>
                                                <p style={{ margin: "0px" }}><Skeleton.Input style={{ width: 150 }} active size="small" /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={{ ...info, display: "flex", alignItems: "center" }}>
                                        <Skeleton.Avatar active shape="square" style={{ marginRight: "20px" }} />
                                        <Skeleton.Input style={{ width: 150 }} active size="small" />
                                    </div>
                                    <div style={info}>
                                        <Skeleton.Button style={{ width: "230px" }} active size="large" shape="round" />
                                    </div>
                                </div>
                            )
                    }
                </div>

            </Col>
        </Row>
        )
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchUserInfo
        },
        dispatch
    );

const mapStateToProps = (state) =>
{
    return {
        user: state.app.user,
        token: state.app.user.token,
        userInfo: state.userInfo.userInfo
    };
};

const newSubscribeComplete = connect(
    mapStateToProps, mapDispatchToProps
)(SubscribeComplete);

export default newSubscribeComplete;