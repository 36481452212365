import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import { Checkbox, Button, Form, Input, Row, Col, Modal, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { bindActionCreators } from "redux";
import PlainButton from "../Common/Button";
import { fetchUserInfo } from "../Actions/UserProfileAction";
import { Skeleton } from "antd";
import HttpService from "../Common/HttpService";

const closeButtonStyle = {
    width: "36px",
    height: "36px",
    marginTop: "-40px",
    marginRight: "-10px"
}

const label = {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21.92px',
    color: '#9f9f9f'
}

const info = { textAlign: "left", padding: "55px 15px 0px 15px", clear: "left" }

const infoValue = {
    color: '#000000',
    fontWeight: 700,
    paddingTop: '8px'
}

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};

const buttonLoader = <LoadingOutlined style={{ fontSize: 28, color: "white", margin: "-8px 5px 0px 8px" }} spin />;

class UserProfileEdit extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loaded: false,
            deleteMeModalShow: false,
            buttonLoader: false
        };

        document.title = "StyleSend - Profile Edit";

        this.deleteMe = this.deleteMe.bind(this);
    }
    componentDidMount()
    {
        console.log(this.props.token);
        
        this.props.fetchUserInfo(this.props.token, () =>
        {
            this.setState({ loaded: true })
        });
    }

    async deleteMe() {
        this.setState({deleteMeBtnLoading: true});
        HttpService.get("dashboard/user/deleteme", this.props.token).then( res => {
            this.setState({deleteMeBtnLoading: false});

            if (res.data.status) {
                message.info(res.message);
                this.props.navigate("/logout");
            } else {
                message.error(res.message);
            }
        });
        this.setState({deleteMeBtnLoading: false});
    }

    onFinish = (values) => {
        console.log("v", values);

        HttpService.post(this.props.token, "dashboard/user/update", {
                name: values.name,
                orgName: values.orgName
            }).then(res => {
                if(!res.data.status) {
                    message.error(res.data.message);
                } else {
                    message.success(res.data.message);
                    let userLocal = JSON.parse(localStorage.getItem('user'));
                    console.log("userLocal", userLocal);
                    userLocal.name = values.name;
                    console.log("userLocal2", userLocal);
                    localStorage.setItem('user', JSON.stringify(userLocal));
                    window.location.reload();
                }
            });
    }

    render()
    {
        const user = this.props.user;
        const userRoleToManageUser = ["Store Associate", "Manager"];
        return (
            <div className="page profile-page profile-edit-page">
                {
                    this.state.loaded
                        ?
                        (
                            <div style={{ padding: "50px 50px" }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "35px" }}>
                                    <div className="profile-wrapper" style={{position: "relative"}}>
                                        <img
                                            src={this.props.userInfo.picture === "" ? require("../images/user_default2.jpg") : this.props.userInfo.picture}
                                            style={{
                                                borderRadius: "50%",
                                                marginRight: "15px",
                                                filter: "drop-shadow(0px 20px 15px rgba(154,161,169,0.4))",
                                                height: "100px",
                                                width: "100px"
                                            }}
                                            onError={(e) =>
                                            {
                                                e.target.src = require("../images/user_default2.jpg")
                                            }}
                                        />
                                        {this.props.userInfo.picture === "" ? (<span className="initial" style={{position: "absolute", color: 'white', fontSize: '48px', width: '100px', top: "30px", left: '0', lineHeight: '48px'}}>{user.name.charAt(0)}</span>) : (<></>)} 
                                    </div>
                                    <div style={{ padding: "12px", textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            <h4>{user.name}</h4>
                                            <p style={{ margin: "0px" }}>{this.props.userInfo.organization}</p>
                                        </div>
                                    </div>
                                </div>
                                <Form
                                    className="profile-edit-form styleade-form"
                                    ref={this.formRef}
                                    {...layout}
                                    name="inviteUser"
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    scrollToFirstError={true}
                                    preserve={false}
                                    initialValues={{
                                         name: this.props.userInfo.name,
                                         orgName: this.props.userInfo.organization,
                                    }}
                                    style={{width: '40%'}}
                                    >
                                    <Form.Item
                                        name="name"
                                        label="Full name"
                                        rules={[{required: true, message: 'Please enter valid full name!', pattern: new RegExp(/^(?!\s*$)([a-z ,&.'-]{1,60})$/i)}]}
                                      >
                                        <Input className="phone_input" placeholder="Full Name" 
                                            // onKeyPressCapture={this.onPhonelHandleKeyPress}
                                            // value={this.state.phoneNumber}
                                            style={{ width: '100%' }}
                                     
                                         />
                                    </Form.Item>

                                    { (this.props.user.shopifyLinked && userRoleToManageUser.includes(user.designation) ) ? (
                                         <Form.Item
                                            name="orgName"
                                            label="Organization name"
                                            rules={[{required: true, message: 'Please enter valid name!', pattern: new RegExp(/^(?!\s*$)(.{1,60})$/i)}]}
                                          >
                                            <Input className="phone_input" placeholder="Organization Name" 
                                                // onKeyPressCapture={this.onPhonelHandleKeyPress}
                                                // value={this.state.phoneNumber}
                                                style={{ width: '100%' }}
                                         
                                             />
                                        </Form.Item>
                                    ) : (<></>)}


                                    <Form.Item {...tailLayout}>
                                        <Row>
                                            <Col span={24} style={{ padding: "12px" }}>
                                                <Button key="submit" className="info-btn" htmlType="submit">
                                                    Save Changes
                                                </Button>

                                                <Button onClick={() =>
                                                {
                                                    this.setState({ deleteMeModalShow: true });
                                                }}
                                                    className="ant-btn-link"
                                                    style={{ textAlign: 'center', fontSize: '15px', color: '#FF7875', border: 'none', paddingLeft: 0, paddingRight: 0 }}>
                                                    Delete Account
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Form>

                                <Modal
                                    key="userDeleteMe"
                                    title={null}
                                    visible={this.state.deleteMeModalShow}
                                    centered
                                    onCancel={() =>
                                    {
                                        this.setState({
                                            deleteMeModalShow: false
                                        })
                                    }}
                                    destroyOnClose={true}
                                    footer={null}
                                    wrapClassName="delete_me_modal"
                                    maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.28)" }}
                                    closeIcon={
                                        <img src={require("../images/icons/dark-close.png")} style={closeButtonStyle} />
                                    }
                                >
                                    <div style={{ width: "100%", padding: "10px", textAlign: "center" }}>
                                        <img src={this.props.userInfo.picture === ""
                                            ?
                                            require("../images/user_default.jpeg")
                                            :
                                            this.props.userInfo.picture}

                                            onError={(e) =>
                                            {
                                                e.target.src = require("../images/user_default.jpeg")
                                            }} style={{ width: "50px", height: "50px", borderRadius: "50%", marginBottom: '18px' }}
                                        />
                                        <h6 style={{ color: "#7831ED", fontWeight: "600", marginBottom: "25px" }}>{this.props.userInfo.name}</h6>
                                        <h5 style={{ marginBottom: "45px", fontWeight: "800" }}>Are you sure you want to delete your StyleSend account?</h5>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
                                            <Button onClick={() =>
                                            {
                                                this.setState({
                                                    deleteMeModalShow: false
                                                })
                                            }} className="modal_button cancel">Cancel</Button>
                                            <Button
                                                onClick={this.deleteMe}
                                                className="modal_button delete_me">
                                                {this.state.deleteMeBtnLoading ?  buttonLoader : <></>} Delete
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>

                            </div>
                        )
                        :
                        (
                            <div style={{ padding: "50px" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "65px" }}>
                                    <Skeleton.Avatar active size={100} />
                                    <div style={{ padding: "12px", textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            <h4><Skeleton.Input style={{ width: 250 }} active size="large" /></h4>
                                            <p style={{ margin: "0px" }}><Skeleton.Input style={{ width: 150 }} active size="small" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={info}>
                                    <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                    <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                </div>
                                <div style={{ ...info, display: "flex", alignItems: "center" }}>
                                    <Skeleton.Avatar active shape="square" style={{ marginRight: "20px" }} />
                                    <Skeleton.Input style={{ width: 150 }} active size="small" />
                                </div>
                                <div style={info}>
                                    <Skeleton.Button style={{ width: "230px" }} active size="large" shape="round" />
                                </div>
                            </div>
                        )
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchUserInfo
        },
        dispatch
    );

const mapStateToProps = (state) =>
{
    return {
        user: state.app.user,
        token: state.app.user.token,
        userInfo: state.userInfo.userInfo
    };
};

const newUserProfileEdit = connect(
    mapStateToProps, mapDispatchToProps
)(UserProfileEdit);

export default newUserProfileEdit;