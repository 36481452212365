import React from "react";
import { Spin, Space } from 'antd';

export default function DownloadLoader()
{
    return (
        <div style={{
            height: '100%',
            width: '100%',
            backgroundColor: "rgba(255,255,255,0.8)",
            marginLeft: "-75px",
            position: "absolute",
            zIndex: '999',
            top : '0px'
        }}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height : '100vh'
            }}>
                <Space size="middle">
                    <Spin size="large" />
                </Space>
            </div>
        </div>
    )
}