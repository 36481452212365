import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from 'react-moment';
import { Checkbox, Button, Row, Col, Radio, Switch, Input, Form, Select, InputNumber, message, Modal } from "antd";
import HttpService from "../../Common/HttpService";
import { bindActionCreators } from "redux";
import PlainButton from "../../Common/Button";
import { fetchUserInfo } from "../../Actions/UserProfileAction";
import { Skeleton } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import { loadPaytm, invokePaytm } from "../../Common/PaytmHelper.js";

const ellipse = require('../../images/icons/ellipse70.svg').default;
const whiteEllipse = require('../../images/icons/ellipse70-white.svg').default;
const checkIcon = require('../../images/icons/check.svg').default;

const buttonLoader = <LoadingOutlined style={{ fontSize: 28, color: "white", margin: "-8px 5px 0px 8px" }} spin />;

const buttonLoaderDark = <LoadingOutlined style={{ fontSize: 32, 
                            color: "#7832ED", 
                            // strokeWidth: "30", 
                            // stroke: '#7831ED', 
                            margin: "-8px 5px 0px 8px" }} spin />;

const { Option } = Select;

const label = {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21.92px',
    color: '#9f9f9f'
}

const info = { textAlign: "left", padding: "55px 15px 0px 15px", clear: "left" }

const infoValue = {
    color: '#000000',
    fontWeight: 700,
    paddingTop: '8px'
}

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
};

class Purchase extends Component
{
    constructor(props)
    {
        super(props);

        let activeTab = "1";
        let paymentInitId = null;
        let amountSummary = null;
        let paymentRetry = false;
        let failedModalShow = false;

        if(props.params.id) {
            paymentRetry = true;
            activeTab = "2";
            paymentInitId = props.params.id;
            amountSummary = {
                total: null,
                selling: null,
                tax: null
            };
            failedModalShow = true;
        }

        this.state = {
            loaded: false,
            activeTab: activeTab,
            planSelected: "1",
            countryCode: "91",
            gatewaySelected: "stripe",
            plans: {},
            durationSelected: "monthly",
            pricesValueLoading: false,
            infoFilled: {
                countryCode: "91"
            },
            paymentInitId: paymentInitId,
            amountSummary: amountSummary,
            paymentRetry: paymentRetry,
            failedModalShow: failedModalShow,
            paymentLoader: false
        };
        document.title = "StyleSend - Purchase";
    }

    componentDidMount()
    {
        this.fetchPlans();
        loadPaytm('jdyJbx34881366917505');

        if(this.state.paymentRetry) {
            console.log("this is a retry");
            this.fetchRetryPaymentInfo();
        }
    }

    fetchRetryPaymentInfo = () =>
    {
        this.setState({ loaded: false });
        HttpService.get("dashboard/payment?id="+this.state.paymentInitId, this.props.token).then(res =>
        {
            if(res.data.status) {
                const resData = res.data.data;
                this.setState({ loaded: true, amountSummary: { total: resData["total"], selling: resData["selling"], tax: resData["tax"] }, planSelected: resData["planSelected"], planDuration: resData["planDuration"] });            
            } else {
                this.setState({ loaded: true });
                message.error("Something went wrong! Please try again later or contact support!");
                this.props.navigate("/purchase");
            }
            
        }).catch(err =>
        {
            this.setState({ loaded: true });
            message.error(err);
        });

    }

    fetchPlans = () =>
    {
        this.setState({ loaded: false });
        HttpService.get("dashboard/plans", this.props.token).then(res =>
        {
            console.log("res", res);
            if(res.data.status) {
                this.setState({ loaded: true, plans: this.formatPlanData(res.data.data)});
            } else {
                this.setState({ loaded: true });
                message.error("Something went wrong! Please try again later or contact support!");
            }
            
        }).catch(err =>
        {
            this.setState({ loaded: true });
            message.error(err);
        });

    }

    formatPlanData = (data) => {
        var planData = {};

        for(let i=0; i < data.length; i++) {

            const obj = data[i];

            planData[obj["planId"]] = {
                "pricing": {
                    "monthly": {
                        "originalPrice": obj["pricing"]["originalPrice"]["monthly"],
                        "sellingPrice": obj["pricing"]["sellingPrice"]["monthly"],
                        "tax": obj["pricing"]["tax"]["monthly"]                        
                    },
                    "annually": {
                        "originalPrice": obj["pricing"]["originalPrice"]["annually"],
                        "sellingPrice": obj["pricing"]["sellingPrice"]["annually"],
                        "tax": obj["pricing"]["tax"]["annually"]  
                    }
                },
                "tagline": obj["tagline"],
                "userLimit": obj["userLimit"]
            };

        }

        console.log("pdd", planData);
        return planData;
    }

    getAmountSummary = (key) => {
    
        if(this.state.amountSummary) { 

            //get from summary if payment initiated
            return this.state.amountSummary[key];

        } else if(key==="total") { 

            //calculating based on selection for initial screen
            const mfactor = this.state.durationSelected=="annually" ? 12 : 1;
            return ((
                        Number(this.state.plans[this.state.planSelected]["pricing"][this.state.durationSelected]["sellingPrice"])*mfactor
                    )
                    +
                    (
                        Number(this.state.plans[this.state.planSelected]["pricing"][this.state.durationSelected]["tax"])*mfactor
                    )).toFixed(2);

        } else if(key==="tax") { 

            //calculating based on selection for initial screen
            const mfactor = this.state.durationSelected=="annually" ? 12 : 1;
            return (Number(this.state.plans[this.state.planSelected]["pricing"][this.state.durationSelected]["tax"])*mfactor).toFixed(2);

        } else if(key==="selling") { 

            //calculating based on selection for initial screen
            const mfactor = this.state.durationSelected=="annually" ? 12 : 1;
            return (Number(this.state.plans[this.state.planSelected]["pricing"][this.state.durationSelected]["sellingPrice"])*mfactor).toFixed(2);
                
        }
    }

    onFinishInfo = (values) => {
        // console.log("v", values);
        this.setState({ paymentLoader: true });

        // window.onbeforeunload = function() {
        //   return "Data will be lost if you leave the page, are you sure?";
        // };

        //init payment
        let payload = {
            email: values.email,
            phoneNumber: values.phoneNumber.toString(),
            countryCode: values.countryCode.toString(),
            businessName: values.businessName,
            planSelected: this.state.planSelected,
            planDuration: this.state.durationSelected
        };

        HttpService.post(this.props.token, "dashboard/payment/start", payload ).then(res =>
        {
            if(res.data.status) {
                this.setState({paymentLoader: false,
                                activeTab: "2",
                                infoFilled: values,
                                paymentInitId: res.data.data.id,
                                amountSummary: { total: res.data.data.total, tax: res.data.data.tax, selling: res.data.data.selling }
                            });
            } else {
                this.setState({ paymentLoader: false });
                message.error("Something went wrong! Please try again!");
            }
            
        }).catch(err =>
        {
            console.log("reached the catch");
            this.setState({ paymentLoader: false });
            message.error(err);
        });

    }

    startPaytmFlow = (paymentId) => {
        this.setState({ paymentLoader: true });

        HttpService.post(this.props.token, "dashboard/payment/tkn", { paymentId: paymentId } ).then(res =>
        {
            if(res.data.status) {
                
                console.log("reached here", res.data.data.token);
                //invoke paytm js checkout
                invokePaytm(paymentId, res.data.data.token, this.state.amountSummary.total, (() => console.log("CLOSEED")), (() => this.setState({ paymentLoader: false })) );
            } else {
                this.setState({ paymentLoader: false });
                message.error("Something went wrong! Please contact support!");
            }
            
        }).catch(err =>
        {
            this.setState({ paymentLoader: false });
            message.error(err);
        });

        this.setState({ paymentLoader: true });
    }

    onFinishMethod = (values) => {
        console.log("gatewaySelected", this.state.gatewaySelected, this.state.paymentInitId);
        // window.location.href="complete";
        if(this.state.gatewaySelected==="Paytm") {
            this.startPaytmFlow(this.state.paymentInitId);
        }
    }

    switchDuration = (e) => {
        this.setState({pricesValueLoading: true});
        setTimeout(() =>
            {
                if(e) {
                    this.setState({durationSelected: "annually", pricesValueLoading: false});
                } else {
                    this.setState({durationSelected: "monthly", pricesValueLoading: false});
                }
            }, 800);
    }

    render()
    {
        const user = this.props.user;
        const countryCodeSelector = (
            <Form.Item name="countryCode" noStyle>
              <Select style={{ width: 110 }} className="countrycode_dropdown">
               
                <Option value="91"><img src="/IN.svg" style={{width:'24px'}} alt="in" /> <span style={{position: 'relative', top: '2px'}}>&nbsp;+91</span></Option>
                 <Option value="1" selected={true}><img src="/US.svg" style={{width:'24px'}} alt="us" /> <span style={{position: 'relative', top: '2px'}}>&nbsp;+1</span></Option>
              </Select>
            </Form.Item>
        );
        return (    
        <Row>
            <Col sm={24} md={24} lg={18}>        
                <div className="page purchase-page">
                    
                    {
                        this.state.loaded
                            ?
                                (
                                    <div className="page-container purchase-container">
                                        
                                       <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: '20px', marginTop: '50px' }}>
                                            <div className="page-head-text-box">
                                                <h1 className="page-head-text">Purchase a plan</h1>
                                            </div>
                                        </div>
                                        <div className="purchaseTabs" style={{paddingTop: '30px'}}>
                                                <Button
                                                    type="primary"
                                                    className={"purchaseTab_btn "+( (this.state.activeTab==="1") ? " active":"")}
                                                    shape="round"
                                                    icon={<span className="plusIcon"><img src={(this.state.activeTab==="1") ? ellipse : whiteEllipse} /></span>
                                                    }
                                                    onClick={() => this.setState({activeTab: "1"})}
                                                    >
                                                    Personal info
                                                </Button>
                                                <hr className="horizontalSep"/>
                                                <Button
                                                    type="primary"
                                                    className={"purchaseTab_btn "+( (this.state.activeTab==="2") ? " active":"")}
                                                    shape="round"
                                                    icon={<span className="plusIcon"><img src={(this.state.activeTab==="2") ? ellipse : whiteEllipse} /></span>
                                                    }
                                                    // onClick={() => this.setState({activeTab: "2"})}
                                                    >
                                                    Payment
                                                </Button>
                                                <hr className="horizontalSep"/>
                                                <Button
                                                    type="primary"
                                                    className={"purchaseTab_btn "+( (this.state.activeTab==="3") ? " active":"")}
                                                    shape="round"
                                                    icon={<span className="plusIcon"><img src={(this.state.activeTab==="3") ? ellipse : whiteEllipse} /></span>
                                                    }
                                                    // onClick={}
                                                    >
                                                    Purchase Complete
                                                </Button>
                                        </div>
                                        {this.state.activeTab==="1" ? (
                                        <>
                                            <div className="planSelection">
                                                <Row style={{clear: "both"}}>
                                                    <Col className="col-one" lg={8} md={24} sm={24}>
                                                        <div className={"planSelectBox "+(this.state.planSelected==="1" ? "active": "")} onClick={() => this.setState({planSelected: "1"})}>
                                                            <Radio checked={this.state.planSelected==="1"}><span className="planTitle">Starter</span></Radio>
                                                            <div className="planInfo">
                                                                <p className="sm-hide" style={{paddingTop: '12px'}}>{this.state.plans["1"]["tagline"]}</p>
                                                                <p><span className="priceHighlight">{(this.state.pricesValueLoading) ? (buttonLoaderDark) : "$"+this.state.plans["1"]["pricing"][this.state.durationSelected]["sellingPrice"]}</span> per month</p>
                                                                <p className="sm-hide"><span className="check-icon"><img src={checkIcon} /></span> Upto <b>{this.state.plans["1"]["userLimit"]}</b> members</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {(!this.props.isRenew) ? (<Col className="col-two" lg={8} md={24} sm={24}>
                                                        <div className={"planSelectBox "+(this.state.planSelected==="2" ? "active": "")} onClick={() => this.setState({planSelected: "2"})}>
                                                            <Radio  checked={this.state.planSelected==="2"}><span className="planTitle">Growth</span></Radio>
                                                            <div className="planInfo">
                                                                <p className="sm-hide" style={{paddingTop: '12px'}}>{this.state.plans["2"]["tagline"]}</p>
                                                                <p><span className="priceHighlight">{(this.state.pricesValueLoading) ? (buttonLoaderDark) : "$"+this.state.plans["2"]["pricing"][this.state.durationSelected]["sellingPrice"]}</span> per month</p>
                                                                <p className="sm-hide"><span className="check-icon"><img src={checkIcon} /></span> Upto <b>1{this.state.plans["1"]["userLimit"]}</b> members</p>
                                                            </div>
                                                        </div>
                                                    </Col>) : <></>}
                                                </Row>
                                                <Row style={{paddingTop: '44px'}}>
                                                    <Col lg={16} md={24} className={"durationSwitch "+((this.props.isRenew) ? "leftAlign" : "")}>
                                                        <span style={{fontSize: '18px', paddingRight: '24px'}}>Monthly</span> 
                                                        <Switch onChange={this.switchDuration} /> 
                                                        <span style={{fontSize: '18px', paddingLeft: '24px'}}>Annually</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row style={{paddingTop: '44px'}}>
                                                <Col lg={14} md={12} sm={20} className="purchaseFormCol">
                                                    <h4 className="mobile-title sm-visible">Personal Information</h4>
                                                    <Form
                                                        className="styleade-form"
                                                        ref={this.formRef}
                                                        {...layout}
                                                        name="inviteUser"
                                                        onFinish={this.onFinishInfo}
                                                        // onFinishFailed={this.onFinishFailed}
                                                        scrollToFirstError={true}
                                                        preserve={false}
                                                        initialValues={{
                                                            ...this.state.infoFilled
                                                        }}
                                                        >
                                                        <Form.Item
                                                            name="email"
                                                            label="Email"
                                                            rules={[{ type: 'email', required: true, message: "Please enter valid email" }]}
                                                          >
                                                            <Input className="email_input" placeholder="example@youremail.com" 
                                                                // onKeyPressCapture={this.onPhonelHandleKeyPress}
                                                                // value={this.state.phoneNumber}
                                                                style={{ width: '100%' }}
                                                         
                                                             />
                                                        </Form.Item>
                                                        <Form.Item
                                                        name="phoneNumber"
                                                        className="phone_box"
                                                        label="Phone number"
                                                        rules={[{ required: true, message: 'Please enter phone number', pattern: new RegExp(/^[1-9]\d{7,11}$/) }]}
                                                      >
                                                        <InputNumber className="phone_input input_number" ref={this.phoneRef} addonBefore={countryCodeSelector} placeholder="Enter phone number" 
                                                            // onKeyPressCapture={this.onPhonelHandleKeyPress}
                                                            // value={this.state.phoneNumber}
                                                            style={{ width: '100%' }}
                                                            // onChange={this.onPhoneChange}
                                                            type="number"
                                                            min="0"
                                                            onKeyDown={(event) => {
                                                                if(event.code==='ArrowDown' || event.code==='ArrowUp' || event.code==='KeyE') {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            controls={false}
                                                            keyboard={false}

                                                            inputMode="numeric"
                                                         />
                                                    </Form.Item>


                                                         <Form.Item
                                                            name="businessName"
                                                            label="Business name"
                                                            rules={[{required: true, message: 'Please enter valid buisness name', pattern: new RegExp(/^(?!\s*$)(.{1,60})$/i)}]}
                                                          >
                                                            <Input className="phone_input" placeholder="Ex. Rick Roll" 
                                                                // onKeyPressCapture={this.onPhonelHandleKeyPress}
                                                                // value={this.state.phoneNumber}
                                                                style={{ width: '100%' }}
                                                         
                                                             />
                                                        </Form.Item>


                                                        <Form.Item {...tailLayout}>
                                                            <Row>
                                                                <Col span={24} style={{ padding: "12px" }}>
                                                                    <Button key="submit" className="info-submit-button invite-btn invite-submit-button" style={{backgroundColor: "#7832ED", boxShadow: '0px 17px 41px rgba(120, 50, 237, 0.21)'}} htmlType="submit"
                                                                        disabled={(this.state.pricesValueLoading || this.state.paymentLoader)}
                                                                        >
                                                                        {(this.state.pricesValueLoading || this.state.paymentLoader) ? (buttonLoader) : <></>} Continue
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </>) : <></>}

                                        {this.state.activeTab==="2" ? (
                                            <>
                                               
                                                <Row style={{paddingTop: '44px'}}>
                                                    <Col className="payment-method-col" md={14} sm={24} style={{textAlign: "left"}}>
                                                    <h4 className="mobile-title sm-visible">Payment info</h4>
                                                        <Form
                                                            className="styleade-form methodForm"
                                                            ref={this.formRef}
                                                            {...layout}
                                                            name="methodForm"
                                                            onFinish={this.onFinishMethod}
                                                            // onFinishFailed={this.onFinishFailed}
                                                            scrollToFirstError={true}
                                                            preserve={false}
                                                            initialValues={{
                                                                countryCode: this.state.countryCode,
                                                            }}
                                                            
                                                            >
                                                        
                                                        <Radio.Group value={this.state.gatewaySelected} onChange={(e)=>{this.setState({gatewaySelected: e.target.value});console.log("eee", e.target.value)}}
                                                         style={{ paddingBottom: '44px'}}>
                                                           <Radio value={"stripe"}><span className="planTitle">Stripe</span></Radio>
                                                           <Radio value={"Paytm"}><span className="planTitle">Paytm</span></Radio>
                                                        </Radio.Group>


                                                            <Form.Item {...tailLayout}>
                                                                <Row>
                                                                    <Col span={24} style={{ padding: "12px" }}>
                                                                        <Button key="submit" className="invite-btn invite-submit-button payment-final-btn" style={{backgroundColor: "#7832ED"}} htmlType="submit"
                                                                         disabled={(this.state.pricesValueLoading || this.state.paymentLoader)}>
                                                                            {this.state.paymentLoader ? (buttonLoader): <></>}
                                                                            Pay ${this.state.amountSummary.total}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </>) : <></>}

                                       
                                    </div>
                                )

                            :
                            (
                                <div style={{ padding: "50px" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "65px" }}>
                                        <Skeleton.Avatar active size={100} />
                                        <div style={{ padding: "12px", textAlign: "left", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <div>
                                                <h4><Skeleton.Input style={{ width: 250 }} active size="large" /></h4>
                                                <p style={{ margin: "0px" }}><Skeleton.Input style={{ width: 150 }} active size="small" /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={info}>
                                        <h6 style={label}><Skeleton.Input style={{ width: 150 }} active size="small" /></h6>
                                        <h6><Skeleton.Input style={{ width: 100 }} active size="small" /></h6>
                                    </div>
                                    <div style={{ ...info, display: "flex", alignItems: "center" }}>
                                        <Skeleton.Avatar active shape="square" style={{ marginRight: "20px" }} />
                                        <Skeleton.Input style={{ width: 150 }} active size="small" />
                                    </div>
                                    <div style={info}>
                                        <Skeleton.Button style={{ width: "230px" }} active size="large" shape="round" />
                                    </div>
                                </div>
                            )
                    }
                </div>

            </Col>
            <Col sm={24} md={24} lg={6} className="purchaseSidebarCol" style={{backgroundColor: "#fff", boxShadow: "-30px 0px 40px #efefef"}}>
                {
                this.state.loaded ? (
                <div className="purchaseSidebar" style={{textAlign: "left"}}>
                    <h3><span className="planTitle" style={{fontSize: "28px", fontWeight: "700"}}>{this.state.planSelected==="2" ? "Growth" : "Starter"}</span></h3>
                    <div>
                        <p style={{paddingTop: '12px'}}>{this.state.plans[this.state.planSelected]["tagline"]}</p>
                        <Row>
                            <Col>
                                <p><span className="priceHighlight">{(this.state.pricesValueLoading) ? (buttonLoaderDark) : "$"+this.state.plans[this.state.planSelected]["pricing"][this.state.durationSelected]["sellingPrice"]}</span></p>
                            </Col>
                            <Col>
                                <p style={{paddingLeft: '15px'}}><span> per month <br/> paid {this.state.durationSelected} </span></p>
                            </Col>
                        </Row>     
                    </div>
                    <div className={"planSelectBox"} style={{textAlign: "left", width: "auto", marginTop: '37px', paddingTop: '32px'}}>
                        <div className="">
                            <p>Info</p>
                            <Row>
                                <Col span={12}>
                                    <p><span style={{fontSize: '20px', fontWeight: '700'}}>{this.state.planSelected==="2" ? "Growth" : "Starter"} Subscription</span></p>
                                </Col>
                                <Col style={{textAlign: 'right'}} span={12}>
                                    <p><span className="priceHighlight" style={{fontSize: '28px'}}>{(this.state.pricesValueLoading) ? (buttonLoaderDark) : "$"+
                                        (this.getAmountSummary("selling"))
                                    }</span></p>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '6px'}}>
                                <Col span={12}>
                                    <p><span style={{fontSize: '20px', fontWeight: '700'}}>Tax</span></p>
                                </Col>
                                <Col style={{textAlign: 'right'}} span={12}>
                                    <p><span className="priceHighlight" style={{fontSize: '18px'}}>{(this.state.pricesValueLoading) ? (buttonLoaderDark) : "$"+
                                        (this.getAmountSummary("tax"))
                                    }</span></p>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '6px', borderTop: 'solid 1px #dcdcdc', paddingTop: '16px'}}>
                                <Col span={12}>
                                    <p><span style={{fontSize: '20px', fontWeight: '700'}}>Total</span></p>
                                </Col>
                                <Col style={{textAlign: 'right'}} span={12}>
                                    <p><span className="priceHighlight" style={{fontSize: '18px'}}>{(this.state.pricesValueLoading) ? (buttonLoaderDark) : "$"+
                                     (this.getAmountSummary("total"))
                                    }</span></p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                ) : <></>
                }
            </Col>
            <Modal
                title={null}
                visible={this.state.failedModalShow}
                onCancel={() =>
                {
                    this.setState({
                        failedModalShow: false
                    })
                }}
                footer={null}
                wrapClassName="logout_modal"
                destroyOnClose={true}
            >
                <div style={{ width: "100%", padding: "10px", textAlign: "center" }}>
                    <h5 style={{ marginBottom: "45px", fontWeight: "800" }}>Oops, your payment failed.</h5>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
                        <Button onClick={() =>
                        {
                            this.setState({
                                failedModalShow: false
                            })
                        }} className="logout_modal_button logout">Retry Payment</Button>
                       
                    </div>
                </div>
            </Modal>
        </Row>
        )
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchUserInfo
        },
        dispatch
    );

const mapStateToProps = (state) =>
{
    return {
        user: state.app.user,
        token: state.app.user.token,
        userInfo: state.userInfo.userInfo
    };
};

const newPurchase = connect(
    mapStateToProps, mapDispatchToProps
)(Purchase);

export default newPurchase;