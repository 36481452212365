import React from "react";

const style = {
    width: "20px",
    height: "auto",
    maxHeight: "30px",
}

const circleStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    // backgroundColor: '#6A10F5',
    background: 'linear-gradient(180deg, #6000F7 0%, #8A4DEF 100%)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
    float: 'left',
    marginRight: '21px'
}
const TabIconActive = (props) =>
{
    return (
        <div style={circleStyle}>
            <img src={props.source} style={style} />
        </div>
    )
}


export default TabIconActive;