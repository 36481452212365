import HttpService from "../Common/HttpService";
import { getUserInfo } from "./actionTypes";


const def = () =>
{

}

export const fetchUserInfo = (token, changeCompState = def) => (dispatch) =>

    HttpService.post(token, "dashboard/user/profile", {}).then((res) =>
    {
        console.log("reaching here???");
        if (res.data.status)
        {
            dispatch(
                changeUserInfo(res.data.data)
            );
            changeCompState();

            //also update local storage items for good ux
            let localUser = JSON.parse(localStorage.getItem("user"));
            if(res.data.data.loggedOnMobile != localUser.loggedOnMobile) {
                localUser.loggedOnMobile = res.data.data.loggedOnMobile;
                localStorage.setItem("user", JSON.stringify(localUser));
            }
        } else
        {
            console.log("Error in Fetching");
        }
    });

export const changeUserInfo = (data) =>
{
    return {
        type: getUserInfo,
        payload: {
            userInfo: data,
        },
    };
};