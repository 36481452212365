import produce from "immer";
import { getDevicesAction } from "../Actions/actionTypes";

const initialState =
{
    devicesArr: [],
    totalResults: 0,
};

export const changeDevicesReducer = (state = initialState, action) =>
    produce(state, (draft) =>
    {
        switch (action.type)
        {
            case getDevicesAction:
                {
                    draft["devicesArr"] = action.payload.devicesArr;
                    draft["totalResults"] = action.payload.devicesArr.length;
                    break;
                }

            default:
                return draft;
        }
    });
