export const loadPaytm = (mid) => {
	if(!window.Paytm || !window.Paytm.CheckoutJS) {
		const script = document.createElement('script');
		//paytm script
		script.src = "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/"+mid+".js";
		script.async = true;
		script.id="paytmScriptTag000";
		//script.setAttribute("onload","invokePaytmConfig()");
		document.body.appendChild(script);
	}
}

export const invokePaytm = (orderId, token, amount, paytmCloseHandler, stopLoading) => {

	var config = {
				"root": "",
				"flow": "DEFAULT",
				"data": {
					"orderId": orderId, /* update order id */
					"token": token, /* update token value */
					"tokenType": "TXN_TOKEN",
					"amount": amount, /* update amount */
				},
				"handler": {
					"notifyMerchant": function(eventName,data){
					//refreshing on close
					if(eventName==="APP_CLOSED") {
						paytmCloseHandler();
						//window.location.reload();
					}
					//window.location.reload();
					console.log("notifyMerchant handler function called");
					console.log("eventName => ",eventName);
					console.log("data => ",data);
				} 
			}
		};

		//console.log(config);

		// initialze configuration using init method 
		window.Paytm.CheckoutJS.init(config).then(function onSuccess() {

			// after successfully updating configuration, invoke JS Checkout
			window.Paytm.CheckoutJS.invoke();
			window.location.hash='#paytm';
			stopLoading();

		}).catch(function onError(error){
			console.log("error => ",error);
		});

}