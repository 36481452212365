import React, { useState, useRef, useEffect } from "react";
import { Button, Popover, Row, Col, Space, Input } from "antd";
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';


const dateFormat = 'YYYY-MM-DD HH:mm:ss';

let selectedFromDate = "";
let selectedToDate = moment().format(dateFormat);
let currentDate = moment().format(dateFormat);

let fromInputBox = "";
let toInputBox = "";

const DateFilter = (props) =>
{

    const dateRef = useRef(null);

    const [filterText, setFilterText] = useState(props.startText != undefined ? props.startText : "All Time");
    const [startDate, setStartDate] = useState(
        props.startText != undefined
            ? props.startText === "Last 30 days"
                ? moment.utc().subtract(30, 'days').format(dateFormat)
                : ""
            : "");
    const [endDate, setEndDate] = useState(props.startText != undefined ? moment().format(dateFormat) : "");
    const [enteredToDate, setEnteredToDate] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [pickerVisible, setPickerVisible] = useState(false);
    const [arrowDown, toggleDownArrow] = useState(true);
    const [rangePickerStyle, togglePickerStyle] = useState();
    const [fromBoundary, setFromBoundary] = useState("");
    const [selectedInputBox, setSelectedInputBox] = useState("");

    let customFromDate = "";
    let customToDate = "";

    const setFilter = (start, end) =>
    {
        props.setDateFilter(start, end);
    }

    function isSelectingFirstDay(from, to, day)
    {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    // On clicking the first day on the calendar.
    function handleDayClick(day, modifiers = {})
    {

        let selected = selectedInputBox;

        if (startDate !== "" && endDate !== "")
        {
            setStartDate("");
            setEndDate("");
            setEnteredToDate("");
            selected = "from";
            setSelectedInputBox("from");
            selectedToDate = moment().format(dateFormat);
        }

        if (modifiers.disabled)
        {
            return;
        }

        const from = startDate;
        const to = endDate;

        if (isSelectingFirstDay(from, to, day) && selected === "from")
        {
            if (day > selectedToDate)
            {
                return;
            }

            setSelectedInputBox("to");

            selectedFromDate = day;
            selectedToDate = currentDate;

            setStartDate(day);
        }
        else
        {
            if (day < selectedFromDate)
            {
                return;
            }

            selectedToDate = day;

            setEndDate(day);
            setEnteredToDate(day);
        }

        fetchData();
    }

    function handleDayMouseEnter(day)
    {
        const from = startDate;
        const to = endDate;

        if (!isSelectingFirstDay(from, to, day))
        {
            setEnteredToDate(day);
            customToDate = day;
        }

        setFilterText("Custom");
    }

    function handleDateOptionClick(option, close = true)
    {
        currentDate = moment().format(dateFormat);

        if (option == "Reset")
        {
            option = "All Time";

            setStartDate("");
            setEndDate("");
            setEnteredToDate("");

            selectedFromDate = "";
            selectedToDate = currentDate;

            togglePickerStyle({});

            setPickerVisible(!close);
        }
        else if (option == "All Time" && filterText != option)
        {
            selectedFromDate = "";
            selectedToDate = currentDate;

            setStartDate("");
            setEndDate("");
        }
        else if (option == "Today" && filterText != option) 
        {
            selectedFromDate = selectedToDate = currentDate;

            setStartDate(new Date(selectedFromDate));
            setEndDate(new Date(selectedToDate));
            setEnteredToDate(new Date(selectedToDate));
        }
        else if (option == "This Week" && filterText != option)
        {
            selectedFromDate = moment.utc().clone().startOf('week').format(dateFormat);
            selectedToDate = currentDate;

            setStartDate(new Date(selectedFromDate));
            setEndDate(new Date(selectedToDate));
            setEnteredToDate(new Date(selectedToDate));
        }
        else if (option == "Last 30 days" && filterText != option)
        {
            selectedFromDate = moment.utc().subtract(30, 'days').format(dateFormat);
            selectedToDate = currentDate;

            setStartDate(new Date(selectedFromDate));
            setEndDate(new Date(selectedToDate));
            setEnteredToDate(new Date(selectedToDate));
        }
        else if (option == "This Year" && filterText != option)
        {
            selectedFromDate = moment.utc().clone().startOf('year').format(dateFormat);
            selectedToDate = currentDate;

            setStartDate(new Date(selectedFromDate));
            setEndDate(new Date(selectedToDate));
            setEnteredToDate(new Date(selectedToDate));
        }
        else
        {
            return;
        }

        setFilterText(option);
        fetchData();
    }

    // Calls the API with the filters.
    function fetchData()
    {
        let fromDate = formatDate(selectedFromDate);
        let toDate = formatDate(selectedToDate);

        const from = fromDate == "" ? "" : moment(fromDate).utc().format(dateFormat);
        const to = toDate == "" ? moment(toDate).add(1, 'day').utc().format(dateFormat) : moment(toDate).add(1, 'day').utc().format(dateFormat);

        setFilter(from, to);
    }

    function renderCalendar()
    {
        const from = startDate;
        const to = endDate;
        const enteredTo = enteredToDate;

        const modifiers = { start: from, end: enteredTo };
        const disabledDays = { before: fromBoundary, after: new Date() };
        const selectedDays = [from, { from, to: enteredTo }];

        return (
            <div>
                <div>
                    <img onClick={() =>
                    {
                        setPickerVisible(false);
                        togglePickerStyle({});

                    }} src={require('../images/icons/dark-close.png')} style={{ cursor: 'pointer', height: "32px", width: "32px", float: "right", marginRight: "-30px" }} />
                </div>
                <DayPicker
                    className="Range"
                    numberOfMonths={2}
                    onDayClick={handleDayClick}
                    selectedDays={selectedDays}
                    disabledDays={disabledDays}
                    modifiers={modifiers}
                    onDayMouseEnter={handleDayMouseEnter}
                />
            </div>
        );
    }

    function formatDate(date)
    {
        if (date == "") return date;

        return moment(date).format("DD MMM YYYY");
    }

    function handleBlur()
    {
        setPickerVisible(false);
        togglePickerStyle({});
    }


    const DateFilterContent = (
        <div style={{ width: "390px", padding: '12px' }} className="date-filter"
            onFocus={() =>
            {
                toggleDownArrow(false);
            }}
            onBlur={() =>
            {
                toggleDownArrow(true);
            }}
            onMouseEnter={() =>
            {
                toggleDownArrow(false);
            }}
            onMouseDown={() =>
            {
                toggleDownArrow(false);
            }}
            onMouseUp={() =>
            {
                toggleDownArrow(false);
            }}
        >
            <Row>
                <Col span={12}>
                    <h6 style={{ fontWeight: "900" }}>Time Frame</h6>
                </Col>
                <Col span={12}>
                    <Button style={{ float: 'right' }} type="link" onClick={() =>
                    {
                        handleDateOptionClick("Reset");
                        setIsVisible(false);
                    }}>Reset</Button>
                </Col>
            </Row>
            <br />
            <Row style={{ display: "flex", justifyContent: "center" }}>
                <Popover visible={pickerVisible} overlayStyle={{ left: "200px !important" }} placement="bottomLeft" content={renderCalendar} trigger="click">
                    <Row>
                        <Col span={11}>
                            <Input placeholder="From" value={formatDate(startDate)} size="large" ref={(input) => fromInputBox = input}
                                prefix={
                                    <img src={require('../images/icons/calendar.png')} style={{ marginRight: "4px", height: "21px", width: "21px" }} />
                                }
                                onFocus={() =>
                                {
                                    setPickerVisible(true);
                                    togglePickerStyle({ width: "602px" });
                                    setSelectedInputBox("from");
                                }}
                            />
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Input placeholder="To" value={formatDate(endDate)} size="large" ref={(input) => toInputBox = input}
                                prefix={
                                    <img src={require('../images/icons/calendar.png')} style={{ marginRight: "4px", height: "21px", width: "21px" }} />
                                }
                                onFocus={() =>
                                {
                                    setPickerVisible(true);
                                    togglePickerStyle({ width: "602px" });
                                    setSelectedInputBox("to");
                                }}
                            />
                        </Col>
                    </Row>
                </Popover>
            </Row>
            <hr />
            <Space direction="vertical">
                <Button type="link" onClick={() => handleDateOptionClick("All Time")} style={filterText === "All Time" ? { color: '#690FF5', fontWeight: "800" } : { color: 'black' }}>All Time</Button>
                <Button type="link" onClick={() => handleDateOptionClick("Today")} style={filterText === "Today" ? { color: '#690FF5', fontWeight: "800" } : { color: 'black' }}>Today</Button>
                <Button type="link" onClick={() => handleDateOptionClick("This Week")} style={filterText === "This Week" ? { color: '#690FF5', fontWeight: "800" } : { color: 'black' }}>This Week</Button>
                <Button type="link" onClick={() => handleDateOptionClick("Last 30 days")} style={filterText === "Last 30 days" ? { color: '#690FF5', fontWeight: "800" } : { color: 'black' }}>Last 30 Days</Button>
                <Button type="link" onClick={() => handleDateOptionClick("This Year")} style={filterText === "This Year" ? { color: '#690FF5', fontWeight: "800" } : { color: 'black' }}>This Year</Button>
            </Space>
        </div >
    )

    return (

        <Popover overlayStyle={rangePickerStyle} placement="bottomLeft" trigger="click" content={DateFilterContent} onVisibleChange={(e) =>
        {
            handleBlur();
            setIsVisible(e);

        }}
            visible={isVisible}
        >
            <Button type="link" style={{ color: 'black', display: 'flex', alignItems: "center" }} >
                <span>Show Reports for: </span>&nbsp;<span style={{ color: '#690FF5', fontWeight: "800" }}>{filterText}</span> <DownOutlined style={{ fontSize: '12px', fontWeight: "900" }} rotate={isVisible ? 180 : 0} />
            </Button>
        </Popover>
    )
}

export default DateFilter;