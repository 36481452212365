import { changeNavKey } from "./actionTypes";

export const changeNavAction = (key) =>
{
    return {
        type: changeNavKey,
        payload: {
            key: [key],
        },
    };
};