import axios from "axios";
import { Store } from "../Store/store";

class HttpService
{
    baseUrl()
    {
        return Store.getState().init.appData.baseUrl.concat("/");
        //return "https://goldilocks.seenit.in/";
    }

    get = (url, token = "", params = null) =>
        new Promise((resolve) =>
        {
            axios
                .get(this.baseUrl() + url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    params,
                })
                .then(function (response)
                {
                    resolve(response);
                })
                .catch(function (error)
                {
                    console.log("Error in fetching data" + error);

                    if ((error.response && error.response.status === 401) || (error.response && error.response.status === 403))
                    {
                        window.location = window.location.origin + "/logout";
                    }
                });
        });

    patch = (token, url, data) => new Promise(resolve =>
    {
        axios.patch(this.baseUrl() + url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function (response)
            {
                resolve(response);
            })
            .catch(function (error)
            {
                console.log("error ", error);

                if ((error.response && error.response.status === 401) || (error.response && error.response.status === 403))
                {
                    window.location = window.location.origin + "/logout";
                }
            });
    })

    patch_files = (token, url, data) => new Promise(resolve =>
    {
        axios.patch(this.baseUrl() + url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function (response)
            {
                resolve(response);
            })
            .catch(function (error)
            {
                console.log("error ", error);

                if ((error.response && error.response.status === 401) || (error.response && error.response.status === 403))
                {
                    window.location = window.location.origin + "/logout";
                }
            });
    })

    post = (token, url, data, source = null) => new Promise((resolve, reject) =>
    {
        axios.post(this.baseUrl() + url, data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source ? source.token : ""
            })
            .then(function (response)
            {
                resolve(response);
            })
            .catch(function (error)
            {

                console.log("error ", error.response);

                if ((error.response && error.response.status === 401) || (error.response && error.response.status === 403))
                {
                    window.location = window.location.origin + "/logout";
                }

                //send a generic error message
                reject("Something went wrong! Please try again or contact support if issue persists!");
            });
    })

    async getMethod(endPoint, headers = {})
    {
        let url = this.baseUrl() + endPoint;

        let response = await fetch(url, { headers: headers });
        response = await response.json();

        return (response);
    }

    async postMethod(endPoint, body = {}, headers = { "Content-Type": "application/json" })
    {
        let url = this.baseUrl() + endPoint;

        let response = await fetch(url, { headers: headers, body: JSON.stringify(body), method: "POST" });
        response = await response.json();

        return (response);
    }
}

export default new HttpService();