import { initApp } from "./actionTypes";

export const initializeApp = (data) =>
{
    // console.log("init action", data);
    return {
        type: initApp,
        payload: data,
    };
};
