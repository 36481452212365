export const changeIssuesAction = "CHANGE_ISSUES";

export const changeCollectionAction = "CHANGE_COLLECTION";

export const changeUsersAction = "CHANGE_USERS";

export const changeCollectionDetailsAction = "CHANGE_COLLECTION_DETAILS";

export const getDevicesAction = "GET_DEVICES";

export const initApp = "INIT_APP";

export const getUserInfo = "GET_USER_INFO";

export const changeNavKey = "CHANGE_NAV_KEY";