import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { changeUsers } from "../Reducers/UsersReducer";
import reducer from "../Reducers/AuthReducer";
import { changeDevicesReducer } from "../Reducers/DeviceReducer";
// import { composeWithDevTools } from "redux-devtools-extension";
import { initializeApp } from "../Reducers/InitReducer";
import { changeConfigReducer } from "../Reducers/AdminSettingsReducer";
import { changeUserInfo } from "../Reducers/UserInfoReducer";
import { changeNavReducer } from "../Reducers/NavReducer";

const rootReducer = combineReducers({
	users: changeUsers,
	app: reducer,
	devices: changeDevicesReducer,
	config: changeConfigReducer,
	init: initializeApp,
	userInfo: changeUserInfo,
	nav: changeNavReducer
})

const createMyStore = () =>
	// createStore(rootReducer, compose(applyMiddleware(logger, thunk), composeWithDevTools());
	createStore(rootReducer, compose(applyMiddleware(logger, thunk)));
// createStore(rootReducer, applyMiddleware(thunk));

export const Store = createMyStore();
