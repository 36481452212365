import produce from "immer";

const initialState =
{
   config : {}
};

export const changeConfigReducer = (state = initialState, action) =>
    produce(state, (draft) =>
    {
        switch (action.type)
        {
            case "CONFIG_DATA":
                {
                    draft["config"] = action.payload.config;
                    break;
                }

            default:
                return draft;
        }
    });
