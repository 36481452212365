import React from "react";
import { connect } from "react-redux";

const style = {
    marginRight: '33px',
    width: "22px",
    height: "auto",
    maxHeight: "30px",
    marginBottom: "5px",
    marginLeft: "6px"
}
const TabIcon = (props) =>
{
    return (
        <>
            <img src={props.source} style={style} />
        </>
    )
}


function mapStateToProps(state)
{
    return {
        keys: state.nav.key
    }
}

export default connect(mapStateToProps, null)(TabIcon);