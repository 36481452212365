import types from "./types";

export function login(payload)
{
	return {
		type: types.LOGIN,
		payload: payload
	}
}

export function logout()
{
	return {
		type: types.LOGOUT,

	}
}

export const setBundleId = (bundle) =>
{
	return {
		type: types.SETBUNDLE,
		payload: {
			bundleId: bundle,
		},
	};
};
