import produce from "immer";
import { changeNavKey } from "../Actions/actionTypes";

const initialState =
{
    key: []
};

export const changeNavReducer = (state = initialState, action) =>
    produce(state, (draft) =>
    {
        switch (action.type)
        {
            case changeNavKey:
                {
                    draft["key"] = [...action.payload.key];
                    break;
                }

            default:
                return draft;
        }
    });
