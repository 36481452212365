import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Button, message, Skeleton, Popover, Modal } from 'antd';
import { connect } from "react-redux";
import TabIcon from "../Common/TabIcon";
import TabIconActive from "../Common/TabIconActive";
import { DownOutlined } from '@ant-design/icons';
import { Navigate } from "react-router-dom";
import { fetchConfigData } from "../Actions/AdminSettingsAction";
import { bindActionCreators } from "redux";
import { fetchUserInfo } from "../Actions/UserProfileAction";
import HttpService from "../Common/HttpService";
import { changeNavAction } from "../Actions/NavAction";

//Icons
import dashboardIcon from "../images/icons/side-bar-icons/dashboard_icon.svg";
import reportIcon from "../images/icons/side-bar-icons/reports_icon.svg";
import insightsIcon from "../images/icons/side-bar-icons/insights_icon.svg";
import organizationSettingIcon from "../images/icons/side-bar-icons/organization-icon.svg";

//Active Icons
import dashboardActive from "../images/icons/side-bar-icons/dashboard_active.svg";
import reportsActive from "../images/icons/side-bar-icons/reports_active.svg";
import insightsActive from "../images/icons/side-bar-icons/insights_active.svg";
import organizationSettingActive from "../images/icons/side-bar-icons/organization-active.svg";

import { LoadingOutlined } from '@ant-design/icons';
const { Header, Content, Sider } = Layout;

const dropMenu = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "6px 4px",
    margin: "8px 2px",
    cursor: "pointer",
}

const buttonLoader = <LoadingOutlined style={{ fontSize: 28, color: "white", margin: "-8px 5px 0px 8px" }} spin />;

class Structure extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            collapsed: true,
            showUserMenu: false,
            loaded: false,
            modalShow: false,
            leftSiderEnabled: false,
            logoutBtnLoading: false
        }
    }

    toggle = () =>
    {
        this.setState({ collapsed: true })
    }

    componentWillMount()
    {
        window.addEventListener('storage', (e) =>
        {
            const storage = localStorage.getItem("user");
            if (storage === null)
            {
                HttpService.get("user/logout", this.props.token);
                return <Navigate to="/logout" />
            }

        });
    }

    componentDidMount()
    {
        this.props.fetchUserInfo(this.props.token, () =>
        {
            this.fetchConfig();
        });
    }


    fetchConfig = () =>
    {
        this.props.fetchConfigData(this.props.token, this.props.bundleId, () =>
        {
            this.setState({ loaded: true })
        });
    }

    setKey = (key) =>
    {
        this.props.changeNavAction(key);
    }

    navigate = (page, e) =>
    {
        if (e.domEvent !== undefined && !e.domEvent.ctrlKey)
        {
            this.setKey(page);
        }
    }

    changeUserMenu = state =>
    {
        this.setState({ showUserMenu: state });
    };

    render()
    {
        const tabs = this.props.topNav !== undefined ? this.props.topNav.topNav : [];

        const userRoleToManageUser = ["Store Associate", "Manager"];
console.log(this.props.user.designation);
        const first_name = this.props.user.name.split(" ")[0];
        const menu = (
            <div style={{ zIndex: "12", padding: "4px", width: "200px" }}>
                <div style={dropMenu}>
                    <img src={require('../images/icons/profile.svg').default} style={{ marginRight: '12px' }} />
                    <Link to="/profile" style={{ textAlign: 'center', fontSize: '15px', color: 'black' }} onClick={() =>
                    {
                        this.setState({ showUserMenu: false })
                    }}>
                        Profile
                    </Link>
                </div>

                { (this.props.user.shopifyLinked && userRoleToManageUser.includes(this.props.user.designation) ) ? (
                    <div style={dropMenu}>
                        <img src={require('../images/icons/cog.svg').default} style={{ marginRight: '12px' }} />
                        <Link to="/users" style={{ textAlign: 'center', fontSize: '15px', color: 'black' }} onClick={() =>
                        {
                            this.setState({ showUserMenu: false })
                        }}>
                            Manage Users
                        </Link>
                    </div>
                ) : (<></>)}

                <div style={dropMenu}>
                    <img src={require('../images/icons/logout.svg').default} style={{ marginRight: '12px' }} />
                    <Button onClick={() =>
                    {
                        this.setState({ modalShow: true, showUserMenu: false });
                    }}
                        style={{ textAlign: 'center', fontSize: '15px', color: 'black', border: 'none', paddingLeft: 0, paddingRight: 0 }}>
                        Logout
                    </Button>
                </div>
            </div>
        );

        const key = this.props.keys[0];

        return (
            <>
                {
                    this.state.loaded ?
                        (
                            <div>
                                <Layout style={{ minHeight: '100vh' }}>
                                {this.state.leftSiderEnabled ? (
                                    <Sider
                                        style={{
                                            overflow: 'hidden',
                                            height: '150vh',
                                            position: 'fixed',
                                            left: 0,
                                            padding: '15px 0px',
                                            zIndex: '3'
                                        }}
                                        width={250}
                                        className="ant-layout-sider ant-layout-sider-light left-sider"
                                        trigger={null}
                                        collapsible
                                        collapsed={this.state.collapsed}
                                        onMouseOver={() =>
                                        {
                                            this.setState({ collapsed: false })
                                        }}
                                        onMouseOut={this.toggle}
                                    >
                                        <div className="logo" style={{ padding: "10px 20px" }}>
                                            {
                                                <Link to="/dashboard/organization-settings" onClick={(e) => this.navigate("organizationSettings", e)}
                                                >
                                                    {this.state.collapsed
                                                        ?
                                                        (
                                                            <img src={require('../images/logo.svg').default} />
                                                        )
                                                        :
                                                        <img src={require('../images/logo_full.svg').default} />
                                                    }
                                                </Link>
                                            }

                                        </div>
                                        <Menu mode="inline"
                                            selectedKeys={this.props.keys}
                                            style={{
                                                marginTop: "35px",
                                                border: 'none',
                                                width: '99%',
                                                marginRight: "0px"
                                            }}>

                                            <Menu.Item key="profile" onClick={(e) => this.navigate("/", e)}
                                                icon={
                                                    key === "profile"
                                                        ?
                                                        <TabIconActive source={organizationSettingActive} />
                                                        :
                                                        <TabIcon source={organizationSettingIcon} />
                                                }>
                                                <Link to="/profile" className="sidebar-link">Profile</Link>
                                            </Menu.Item>

                                           
                                        </Menu>

                                    </Sider>
                                    ) : (<></>)}
                                    <Layout className="site-layout">
                                        <Header className="site-layout-background structure-header">

                                            <Menu mode="horizontal" selectedKeys={this.props.keys} style={{ marginLeft: '120px', paddingTop: '15px', paddingBottom: '15px' }}>

                                                        <Menu.Item key="logo" onClick={(e) => this.navigate("/", e)}
                                                        >
                                                            <Link to="/profile" className="sidebar-link">
                                                                <img src={require("../images/stylesend.png")} alt="Styleade Logo" className="header-logo" style={{width: '215px'}} />
                                                            </Link>
                                                        </Menu.Item>
                                               
                                                <Menu.Item key="profile" style={{ "marginLeft": "auto", padding: "0px 15px" }} className="header-popover-button header-popover-profile">
                                                    <Popover
                                                        content={menu}
                                                        title={null}
                                                        trigger="click"
                                                        placement="bottom"
                                                        overlayClassName="header-popover"
                                                        visible={this.state.showUserMenu}
                                                        onVisibleChange={this.changeUserMenu}
                                                      >
                                                        <Button
                                                            className="profile-icon-btn"
                                                            style={{ border: 'none', height: 'auto', marginTop: '4px', padding: '8px', fontWeight: '900' }}
                                                        >
                                                            
                                                                <img src={this.props.userInfo.picture === ""
                                                                    ?
                                                                    require("../images/user_default2.jpg")
                                                                    :
                                                                    this.props.userInfo.picture}

                                                                    onError={(e) =>
                                                                    {
                                                                        e.target.src = require("../images/user_default2.jpg")
                                                                    }} style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: '15px' }} />
                                                                {this.props.userInfo.picture === ""
                                                                    ? (<span className="initial" style={{position: "absolute", color: 'white', fontSize: '19.2px', fontWeight: '300', width: '40px', top: "0", marginTop: '8px', left: '8px',lineHeight: '46px'}}>{this.props.userInfo.name.charAt(0)}</span>) : (<></>)}
                                           
                                                            <span style={this.state.showUserMenu ? { color: "#690FF5" } : { color: 'black' }}>{first_name}</span>
                                                            <DownOutlined rotate={this.state.showUserMenu ? 180 : 0} style={{ fontSize: '13px', fontWeight: "900", color: "#7831ED" }} />
                                                        </Button>
                                                    </Popover>
                                                </Menu.Item>
                                            </Menu>
                                        </Header>
                                        <Content style={{ overflow: 'initial', backgroundColor: '#FFF', minHeight: '100vh', marginLeft: (this.state.leftSiderEnabled ? '75px' : '0px' ) }}>
                                            <div className="site-layout-background" style={{ textAlign: 'center' }}>
                                                {this.props.children}
                                            </div>
                                        </Content>
                                    </Layout>
                                </Layout>
                                <Modal
                                    title={null}
                                    visible={this.state.modalShow}
                                    onCancel={() =>
                                    {
                                        this.setState({
                                            modalShow: false
                                        })
                                    }}
                                    footer={null}
                                    wrapClassName="logout_modal"
                                    destroyOnClose={true}
                                >
                                    <div style={{ width: "100%", padding: "10px", textAlign: "center" }}>
                                        <img src={this.props.userInfo.picture === ""
                                            ?
                                            require("../images/user_default.jpeg")
                                            :
                                            this.props.userInfo.picture}

                                            onError={(e) =>
                                            {
                                                e.target.src = require("../images/user_default.jpeg")
                                            }} style={{ width: "50px", height: "50px", borderRadius: "50%", marginBottom: '18px' }}
                                        />
                                        <h6 style={{ color: "#7831ED", fontWeight: "600", marginBottom: "25px" }}>{first_name}</h6>
                                        <h5 style={{ marginBottom: "45px", fontWeight: "800" }}>Are you sure you want to logout? </h5>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
                                            <Button onClick={() =>
                                            {
                                                this.setState({
                                                    modalShow: false
                                                })
                                            }} className="modal_button cancel">Cancel</Button>
                                            <Link
                                                params={{ token: this.props.token }}
                                                to="/logout"
                                                onClick={() =>
                                                {
                                                    this.setState({logoutBtnLoading: true});
                                                    HttpService.get("user/logout", this.props.token);
                                                    this.setState({logoutBtnLoading: false});
                                                }}
                                                className="modal_button logout">
                                                {this.state.logoutBtnLoading ?  buttonLoader : <></>} Logout
                                            </Link>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        )
                        :
                        <div>
                            <Layout style={{ minHeight: '100vh' }}>
                                {this.state.leftSiderEnabled ? (<Sider
                                    style={{
                                        overflow: 'hidden',
                                        height: '150vh',
                                        position: 'fixed',
                                        left: 0,
                                        padding: '15px 0px',
                                        zIndex: '3',
                                    }}
                                    width={250}
                                    className="ant-layout-sider ant-layout-sider-light"
                                    trigger={null}
                                    collapsible
                                    collapsed={this.state.collapsed}
                                >
                                    <div className="logo" style={{ padding: "0px 20px" }}>
                                        <Skeleton.Button active shape="round" style={{ width: "210px" }} size="larger" />
                                    </div>
                                    <Menu mode="inline" defaultSelectedKeys={[]} style={{
                                        marginTop: "35px",
                                        border: 'none',
                                        width: '99%'
                                    }}>
                                        <Menu.Item key="2">
                                            <Skeleton.Avatar active size={35} style={{ marginRight: "15px" }} />
                                            <Skeleton.Button active shape="round" style={{ marginBottom: "12px", width: "150px" }} />
                                        </Menu.Item>

                                        <Menu.Item key="3">
                                            <Skeleton.Avatar active size={35} style={{ marginRight: "15px" }} />
                                            <Skeleton.Button active shape="round" style={{ marginBottom: "12px", width: "150px" }} />
                                        </Menu.Item>

                                        <Menu.Item key="4">
                                            <Skeleton.Avatar active size={35} style={{ marginRight: "15px" }} />
                                            <Skeleton.Button active shape="round" style={{ marginBottom: "12px", width: "150px" }} />
                                        </Menu.Item>
                                    </Menu>
                                </Sider>) : (<></>)}
                                <Layout className="site-layout">
                                    <Header className="site-layout-background structure-header">
                                        <Menu mode="horizontal" defaultSelectedKeys={[]} style={{ marginLeft: '120px' }}>
                                            <Skeleton.Button style={{ width: "150px", marginTop: "16px", marginRight: "30px" }} active shape="round" />

                                        </Menu>
                                    </Header>
                                    <Content style={{ overflow: 'initial', backgroundColor: '#FBFCFE', minHeight: '100vh', marginLeft: (this.state.leftSiderEnabled ? '75px' : '0px' ) }}>
                                        <div className="site-layout-background" style={{ textAlign: 'center' }}>
                                            {this.props.children}
                                        </div>
                                    </Content>
                                </Layout>
                            </Layout>
                        </div>
                }
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchConfigData,
            fetchUserInfo,
            changeNavAction
        },
        dispatch
    );

function mapStateToProps(state)
{
    return {
        token: state.app.user.token,
        bundleId: state.app.bundle,
        appName: state.init.appData.name,
        user: state.app.user,
        topNav: state.config.config.dashboard,
        userInfo: state.userInfo.userInfo,
        keys: state.nav.key
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Structure);
