import HttpService from "../Common/HttpService";

export const fetchConfigData = (token, bundleId, changeState) => (dispatch) =>
    HttpService.get("organization/config", token, { bundleId: bundleId }).then(res =>
    {
        if (res.status === 200)
        {
            dispatch(
                changeConfig(res.data.data)
            )
        }
        else
        {
            console.log("Error in Fetching Config Data !!");
        }

        changeState();
    })

export const changeConfig = (data) =>
{
    return {
        type: "CONFIG_DATA",
        payload: {
            config: data,
        },
    };
};

