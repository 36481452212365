import produce from "immer";
import { changeUsersAction } from "../Actions/actionTypes";

const initialState =
{
    usersArr: []
};

export const changeUsers = (state = initialState, action) =>
    produce(state, (draft) =>
    {
        switch (action.type)
        {
            case changeUsersAction:
                {
                    draft["usersArr"] = action.payload.usersArr;
                    break;
                }

            default:
                return draft;
        }
    });
