import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import NotFound from "./Components/NotFound";
import ProtectedRoute from "./Components/ProtectedRoute";
import HOCForRouteProps from "./Components/HOCForRouteProps";
import Users from "./Components/Users/Users";
import Logout from "./Components/Logout";
import UserProfile from "./Components/UserProfile";
import UserProfileEdit from "./Components/UserProfileEdit";
import Purchase from "./Components/Purchase/Purchase";
import PurchaseComplete from "./Components/Purchase/PurchaseComplete";
import Subscribe from "./Components/Subscribe/Subscribe";
import SubscribeComplete from "./Components/Subscribe/SubscribeComplete";

class App extends React.Component
{

	constructor(props)
	{
		super(props);
		this.state = {};
	}

	render()
	{
		// console.log("imports");
		// console.log(Router);
		// console.log(Routes);
		// console.log(Route);
		// console.log(Login);
		// console.log(NotFound);
		// console.log('ProtectedRoute',ProtectedRoute);
		// console.log(HOCForRouteProps);
		// console.log(Logout);

		return (

			<div>
				<Router basename="/">
					<Routes>
						<Route path="/" element={<HOCForRouteProps Component={Login} />} />
						<Route path="/login" element={<HOCForRouteProps Component={Login} />} />
						<Route path="/signup" element={<HOCForRouteProps Component={Login} />} />
						<Route path="/logout" element={<HOCForRouteProps Component={Logout} />} />
						
						<Route path="/" element={<ProtectedRoute Component={UserProfile} />} />
						<Route path="/profile" element={<ProtectedRoute Component={UserProfile} />} />
						<Route path="/profile-edit" element={<ProtectedRoute Component={UserProfileEdit} />} />
						<Route path="/users" element={<ProtectedRoute Component={Users} />} />
						<Route path="/subscribe" element={<ProtectedRoute Component={Subscribe} isRenew={false} />} />
						<Route path="/subscription-complete" element={<ProtectedRoute Component={SubscribeComplete} />} />

						
						<Route path="/purchase" element={<ProtectedRoute Component={Purchase} isRenew={false} />} />
						<Route path="/purchase/:id" element={<ProtectedRoute Component={Purchase} isRenew={false} />} />
						<Route path="/renew" element={<ProtectedRoute Component={Purchase} props={{isRenew: true}} />} />
						<Route path="/complete/:id" element={<ProtectedRoute Component={PurchaseComplete} />} />

						<Route path="*" element={<ProtectedRoute Component={NotFound} />} />
					</Routes>
				</Router>
			</div>
		);
	}
}

export default App;